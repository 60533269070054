.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("../fonts/SFPro/OpenType/SF-Pro-Display-Regular.otf");
  font-weight: 400;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("../fonts/SFPro/OpenType/SF-Pro-Display-Light.otf");
  font-weight: 300;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("../fonts/SFPro/OpenType/SF-Pro-Display-Bold.otf");
  font-weight: 700;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("../fonts/SFPro/OpenType/SF-Pro-Display-Semibold.otf");
  font-weight: 600;
}

body {
  overflow-x: hidden;
  color: #000000;
  font-family: "SF Pro Display", Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.2;
}

.t-top {
  display: block;
  padding: 8px 0;
  background-color: #343433;

  &>.container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
  }

  &__item {
    display: inline-flex;
    align-items: center;
    margin: 0;
    opacity: 0.8;
    color: #fefefe;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.43;
    letter-spacing: 0.14px;

    img,
    svg {
      display: inline-block;
      margin-right: 8px;
    }

    &_link {
      cursor: pointer;
      text-decoration: none;
      transition: all 0.2s ease-out;

      &:hover {
        opacity: 1;
        color: #ffffff;
        text-decoration: none;
      }
    }
  }
}

.t-header {
  display: block;
  padding: 0;
  background: #ffffff;

  &__toggle {
    display: none;
  }

  &__logo {
    display: inline-block;
    max-width: 150px;
    max-height: 55px;

    img,
    svg {
      display: block;
      max-width: 100%;
      max-height: 100%;
    }

    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;

    &:hover {
      transform: scale(0.9);
      color: #ffffff;
      text-decoration: none;
    }
  }

  &__main {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 0;
  }

  &__nav {
    display: inline-block;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: inline-block;

      a {
        display: inline-block;
        padding: 8px 11px;
        color: #343433;
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 0.6px;
        cursor: pointer;
        text-decoration: none;
        transition: all 0.2s ease-out;

        &:hover {
          color: #00b956;
          text-decoration: none;
        }
      }
    }
  }

  &__info {
    display: inline-block;
    margin: 0;
    padding: 6px 18px;
    border-left: 1px solid rgba(52, 52, 51, 0.3);
    border-right: 1px solid rgba(52, 52, 51, 0.3);
    opacity: 0.8;
    color: #343433;
    font-size: 12px;
    font-weight: 300;
    line-height: 1.4;
    letter-spacing: 0.6px;
  }

  &__phone {
    display: inline-block;
    margin-left: 14px;
    color: #00b956;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;

    &:hover {
      color: #343433;
      text-decoration: none;
    }
  }

  &__right {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    align-items: flex-start;
  }

  &__more {
    display: inline-flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 10px 0;
    background-color: #343433;

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      right: 100%;
      width: 0;
      height: 0;
      border-bottom: solid 62px #343433;
      border-left: solid 62px transparent;
    }

    &:after {
      display: block;
      position: absolute;
      content: '';
      left: 100%;
      top: 0;
      width: calc((100vw - 1170px) / 2);
      height: 100%;
      background-color: #343433;
    }
  }

  &__dropper {
    position: relative;

    &:hover {
      .t-header__drop {
        display: block;
      }
    }
  }

  &__drop {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    max-width: 300px;
    margin: 0;
    padding: 18px 0;
    background-color: #343433;
    list-style: none;
    z-index: 4;

    li {
      display: inline-block;

      a {
        display: inline-block;
        padding: 6px 15px;
        color: #ffffff;
        font-size: 14px;
        opacity: 0.7;
        font-weight: 500;
        letter-spacing: 0.6px;
        cursor: pointer;
        text-decoration: none;
        transition: all 0.2s ease-out;

        &:hover {
          opacity: 1;
          color: #ffffff;
          text-decoration: none;
        }
      }
    }
  }

  &__subnav {
    display: inline-block;
    margin: 0;
    padding: 0;
    list-style: none;

    &>li {
      display: inline-block;

      &>a {
        display: inline-block;
        padding: 8px 15px;
        color: #ffffff;
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 0.6px;
        cursor: pointer;
        text-decoration: none;
        transition: all 0.2s ease-out;

        &:hover {
          color: #00b956;
          text-decoration: none;
        }
      }
    }
  }

  &__buttons {
    display: inline-flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
  }

  &__search {
    display: inline-block;
    padding: 10px;

    img,
    svg {
      max-width: 22px;
      max-height: 22px;
    }

    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;

    &:hover {
      opacity: 0.8;
      color: #ffffff;
      text-decoration: none;
    }
  }

  &__basket {
    display: inline-block;
    position: relative;
    padding: 10px;

    &-counter {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      right: 0;
      width: 22px;
      height: 22px;
      padding: 0;
      overflow: hidden;
      border-radius: 999rem;
      background: #00b956;
      color: #ffffff;
      font-size: 12px;
      font-weight: 300;
      text-align: center;
      opacity: 0.9;
    }

    img,
    svg {
      max-width: 22px;
      max-height: 22px;
    }

    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;

    &:hover {
      opacity: 0.8;
      color: #ffffff;
      text-decoration: none;
    }
  }

  &__call {
    display: inline-block;
    margin-left: 15px;
    padding: 13px 27px;
    border-radius: 2px;
    background-color: #00b956;
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.28px;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;

    &:hover {
      background-color: #25e780;
      color: #ffffff;
      text-decoration: none;
    }
  }
}

.t-home-carousel {
  display: block;
  position: relative;

  .swiper-pagination {
    display: inline-flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    left: calc((100vw - 1200px) / 2 + 1170px);
    width: 15px;
    height: 100%;
    bottom: 0;
  }

  .swiper-pagination-clickable .swiper-pagination-bullet {
    margin: 6px 4px;
    width: 8px;
    height: 8px;
    border: 0;
    opacity: 1;
    background-color: #ffffff;
    transition: all 0.2s ease-out;
  }

  .swiper-pagination-bullet-active {
    width: 13px !important;
    height: 13px !important;
    border: 1px solid #ffffff !important;
    background: transparent !important;
  }

  &-card {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    align-items: flex-start;
    min-height: 510px;
    background-position: center center;
    background-size: cover;

    &__content {
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-end;
      align-items: flex-start;
      position: relative;
      width: 100%;
      max-width: 725px;
      margin: -267px 0 0 0;
      height: 267px;
      overflow-y: auto;
      padding: 40px 50px 0 50px;
      background: #ffffff url('../images/home_bg.png') repeat 0 0;
      z-index: 3;
    }

    &__title {
      display: block;
      height: 126px;
      overflow: hidden;
      margin: 0 0 42px 0;
      color: #343433;
      font-size: 35px;
      font-weight: 300;
    }

    &__button {
      display: inline-block;
      padding: 20px 74px;
      background-color: #00b956;
      color: #ffffff;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.32px;
      text-align: center;
      cursor: pointer;
      text-decoration: none;
      transition: all 0.2s ease-out;

      &:hover {
        background-color: #25e780;
        color: #ffffff;
        text-decoration: none;
      }
    }
  }
}

.t-home-wrap {
  padding: 170px 0 120px 0;
  background: url('../images/home_bg.png') repeat calc((100vw - 1200px) / 2) 0;
}

.t-popular {
  display: block;
  margin: 0 0 170px 0;

  &__header {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 0 40px;
    background-color: #0d0d0d;

    &-title {
      display: inline-block;
      margin: 20px 0;
      color: #ffffff;
      font-size: 25px;
      font-weight: 700;
    }

    &-button {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 58px 30px 54px 32px;
      color: rgba(255, 255, 255, 0.8);
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.16px;
      background-color: rgba(52, 52, 51, 0.8);

      img,
      svg {
        margin-left: 10px;
      }

      cursor: pointer;
      text-decoration: none;
      transition: all 0.2s ease-out;

      &:hover {
        background-color: #00b956;
        color: #ffffff;
        text-decoration: none;
      }
    }
  }

  &__cards {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: stretch;
  }

  &-card {
    display: inline-flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1 1 390px;
    min-height: 380px;
    padding: 35px 40px;
    background-color: #f4f4f4;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;

    &:hover {
      transform: scale(1.025);
      text-decoration: none;
    }

    &:nth-child(1) {
      flex: 1 1 780px;
      background: #343433;

      .t-popular-card__title {
        color: #ffffff;
      }

      .t-popular-card__category {
        color: #ffffff;
      }

      .t-popular-card__price {
        color: #ffffff;
      }

      .t-popular-card__oldprice {
        color: #ffffff;
      }
    }

    &:nth-child(3) {
      flex: 1 1 585px;
    }

    &:nth-child(4) {
      flex: 1 1 585px;
      background: #00b956;

      .t-popular-card__title {
        color: #ffffff;
      }

      .t-popular-card__category {
        color: #ffffff;
      }

      .t-popular-card__price {
        color: #ffffff;
      }

      .t-popular-card__oldprice {
        color: #ffffff;
      }
    }

    &:nth-child(5) {
      background: #343433;

      .t-popular-card__title {
        color: #ffffff;
      }

      .t-popular-card__category {
        color: #ffffff;
      }

      .t-popular-card__price {
        color: #ffffff;
      }

      .t-popular-card__oldprice {
        color: #ffffff;
      }
    }

    &:nth-child(6) {
      background: #ffffff;
    }

    &__title {
      display: block;
      margin: 0;
      color: #343433;
      font-size: 18px;
      font-weight: 400;
    }

    &__category {
      display: block;
      margin: 12px 0 0 0;
      opacity: 0.4;
      color: #000000;
      font-size: 13px;
      font-weight: 300;
      letter-spacing: 0.13px;
    }

    &__image {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1 1 100%;
      width: 100%;
      min-height: 240px;

      img,
      svg {
        max-width: 100%;
        max-height: 100%;
      }
    }

    &__price {
      display: block;
      margin: 0;
      color: #343433;
      font-size: 20px;
      font-weight: 700;
    }

    &__oldprice {
      display: block;
      margin: 0;
      opacity: 0.5;
      color: #343433;
      font-size: 14px;
      font-weight: 400;
      text-decoration: line-through;
    }
  }
}

.t-services {
  display: block;
  margin: 0 0 170px 0;

  &__header {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 0 40px;
    background-color: #0d0d0d;

    &-title {
      display: inline-block;
      margin: 20px 0;
      color: #ffffff;
      font-size: 25px;
      font-weight: 700;
    }

    &-button {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 58px 30px 54px 32px;
      color: rgba(255, 255, 255, 0.8);
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.16px;
      background-color: rgba(52, 52, 51, 0.8);

      img,
      svg {
        margin-left: 10px;
      }

      cursor: pointer;
      text-decoration: none;
      transition: all 0.2s ease-out;

      &:hover {
        background-color: #00b956;
        color: #ffffff;
        text-decoration: none;
      }
    }
  }

  &__cards {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: stretch;
  }

  &-card {
    display: inline-block;
    flex: 1 1 390px;
    background-position: center center;
    background-size: cover;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;

    &:hover {
      text-decoration: none;

      .t-services-card__content {
        background: rgba(0, 0, 0, 0.7) !important;
      }

      .t-services-card__icon {
        display: none;
      }

      .t-services-card__number {
        opacity: 0.38;
        color: #ffffff;
      }

      .t-services-card__title {
        color: #ffffff;
      }

      .t-services-card__text {
        display: block;
      }

      .t-services-card__more {
        display: block;
      }
    }

    &:nth-child(1) {
      flex: 1 1 585px;

      .t-services-card__content {
        background: #f4f4f4;
      }
    }

    &:nth-child(2) {
      flex: 1 1 585px;

      .t-services-card__content {
        background: #00b956;
      }

      .t-services-card__title {
        color: #ffffff;
      }

      .t-services-card__number {
        opacity: 0.6;
        color: #ffffff;
      }
    }

    &:nth-child(3) {
      .t-services-card__content {
        background: #343433;
      }

      .t-services-card__title {
        color: #ffffff;
      }

      .t-services-card__number {
        opacity: 0.6;
        color: #ffffff;
      }
    }

    &:nth-child(4) {
      .t-services-card__content {
        background: #ffffff;
      }
    }

    &:nth-child(6) {
      flex: 1 1 1170px;

      .t-services-card__content {
        background: #00b956;
      }

      .t-services-card__title {
        color: #ffffff;
      }

      .t-services-card__number {
        opacity: 0.6;
        color: #ffffff;
      }
    }

    &__content {
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      min-height: 340px;
      padding: 35px 40px;
      background-color: #f4f4f4;
      transition: all 0.2s ease-out;
    }

    &__number {
      display: block;
      width: 100%;
      margin: 0;
      opacity: 0.38;
      color: #000000;
      font-size: 16px;
      font-weight: 700;
      text-align: right;
      transition: all 0.2s ease-out;
    }

    &__icon {
      display: block;
      max-width: 60px;
      max-height: 60px;
      margin-bottom: 20px;
      transition: all 0.2s ease-out;

      img,
      svg {
        max-width: 100%;
        max-height: 100%;
      }
    }

    &__title {
      display: block;
      margin: 0;
      color: #343433;
      font-size: 18px;
      font-weight: 600;
      transition: all 0.2s ease-out;
    }

    &__text {
      display: none;
      max-height: 132px;
      overflow: hidden;
      margin: 36px 0 38px 0;
      opacity: 0.8;
      color: #ffffff;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.38;
      transition: all 0.2s ease-out;
    }

    &__more {
      display: none;
      align-items: center;
      color: #00b956;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.28px;
      transition: all 0.2s ease-out;

      img,
      svg {
        display: inline-block;
        margin-left: 8px;
      }
    }
  }
}

.t-features {
  display: block;
  margin: 0;

  &__cards {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: stretch;
  }

  &__prep {
    display: inline-flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-start;
    padding: 20px 30px;
    width: 100%;
    max-width: 293px;
    min-height: 293px;
    margin: -1px 0 0 -1px;
    border: 1px solid #343433;
    background-color: #343433;
  }

  &__title {
    display: block;
    margin: 0;
    color: rgba(255, 255, 255, 0.87);
    font-size: 30px;
    font-weight: 300;

    b,
    strong {
      color: #00b956;
      font-weight: 700;
    }
  }

  &__end {
    display: inline-flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    padding: 20px;
    width: 100%;
    max-width: 293px;
    min-height: 293px;
    margin: -1px 0 0 -1px;
    border: 1px solid #00b956;
    background-color: #00b956;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.16px;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;

    &:hover {
      transform: scale(1.025);
      color: #ffffff;
      text-decoration: none;
    }
  }

  &-card {
    display: inline-flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: flex-start;
    padding: 30px 30px 20px 30px;
    width: 100%;
    max-width: 293px;
    min-height: 293px;
    margin: -1px 0 0 -1px;
    border: 1px solid #e1e1e1;
    background-color: #ffffff;

    &__number {
      display: block;
      width: 100%;
      opacity: 0.38;
      color: #232323;
      font-size: 16px;
      font-weight: 700;
      text-align: right;
    }

    &__title {
      display: block;
      min-height: 42px;
      margin: 0;
      color: #343433;
      font-size: 16px;
      font-weight: 500;
    }

    &__icon {
      display: block;
      width: 100%;
      max-width: 60px;
      max-height: 60px;
      margin-bottom: 26px;

      img,
      svg {
        display: block;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}

.t-timeline {
  display: block;
  padding: 120px 0;
  background-color: #fafafa;

  &__cards {
    display: block;
  }

  &-card {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: stretch;
    margin-bottom: 2px;

    &:nth-child(even) {
      .t-timeline-card__number {
        margin-left: auto;
      }
    }

    &:nth-child(2n+3) {
      .t-timeline-card__wrap {
        padding-right: 15px;
      }
    }

    &__number {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 150px;
      min-height: 150px;
      padding: 5px;
      background-color: #00b956;
      color: #ffffff;
      font-size: 40px;
      font-weight: 700;
      text-align: center;
    }

    &__wrap {
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      max-width: 510px;
      padding: 26px 45px 26px 50px;
      background-color: #343433;
    }

    &__title {
      display: block;
      margin: 0;
      color: rgba(255, 255, 255, 0.87);
      font-size: 30px;
      font-weight: 300;

      b,
      strong {
        color: #00b956;
        font-weight: 700;
      }
    }

    &__content {
      max-width: 365px;
      padding: 0 5px;

      p {
        display: block;
        margin: 15px 0 0 0;
        opacity: 0.8;
        color: #ffffff;
        font-size: 15px;
        font-weight: 400;
        line-height: 1.47;
      }
    }

    &__subtitle {
      display: block;
      margin: 0;
      color: #ffffff;
      font-size: 18px;
      font-weight: 400;
    }
  }
}

.t-partners {
  display: block;
  padding: 110px 0 120px 0;
  background: #ffffff;

  &__title {
    display: block;
    margin: 0 0 40px 0;
    color: #343433;
    font-size: 25px;
    font-weight: 700;
  }

  &__cards {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: stretch;
  }

  &-card {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    max-width: 235px;
    height: 235px;
    margin: -1px 0 0 -1px;
    border: 1px solid #e1e1e1;
    background-color: #ffffff;

    img,
    svg {
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.t-consult {
  display: block;
  position: relative;
  width: 100vw;
  overflow: hidden;
  background: #fafafa;

  &__title {
    display: block;
    margin: 120px 0 80px 0;
    color: #343433;
    font-size: 30px;
    font-weight: 700;
  }

  &__form {
    display: block;
    width: 100%;
    max-width: 470px;
  }

  &__input {
    display: block;
    position: relative;
    margin: 0 0 30px 0;

    label {
      display: block;
      position: absolute;
      top: 50%;
      left: 30px;
      margin: -12px 0 0 0;
      padding: 0;
      color: rgba(52, 52, 51, 0.35);
      font-size: 20px;
      font-weight: 400;
      letter-spacing: 0.2px;
      pointer-events: none;
      z-index: 1;
      transition: all 0.2s ease-out;
    }

    input {
      display: block;
      width: 100%;
      border: 1px solid #e1e1e1;
      background-color: #ffffff;
      padding: 30px;
      color: #343433;
      font-size: 20px;
      font-weight: 400;
      letter-spacing: 0.2px;
      outline: none;
      transition: all 0.2s ease-out;

      &:focus,
      &:valid {
        &~label {
          top: 0;
          color: rgba(52, 52, 51, 0.7);
          font-size: 18px;
          text-shadow: 0px 1px 0 #ffffff, 0px -1px 0 #ffffff, 1px 0px 0 #ffffff, -1px 0px 0 #ffffff, 1px 1px 0 #ffffff, 1px -1px 0 #ffffff, -1px 1px 0 #ffffff, -1px -1px 0 #ffffff, 0px 2px 0 #ffffff, 0px -2px 0 #ffffff, 2px 0px 0 #ffffff, -2px 0px 0 #ffffff, 2px 2px 0 #ffffff, 2px -2px 0 #ffffff, -2px 2px 0 #ffffff, -2px -2px 0 #ffffff;
        }
      }
    }
  }

  &__button {
    display: block;
    width: 100%;
    padding: 28px 12px;
    border: 0;
    outline: none;
    background-color: #00b956;
    color: #ffffff;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0.2px;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;

    &:hover {
      background-color: #25e780;
      color: #ffffff;
      text-decoration: none;
    }
  }

  &__info {
    display: flex;
    align-items: center;
    margin: 18px 0 0 0;
    color: rgba(52, 52, 51, 0.6);
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.14px;

    img,
    svg {
      margin-right: 15px;
    }
  }

  &__image {
    display: block;
    height: 100%;
    width: auto;
  }
}

.t-home-seo {
  display: block;
  padding: 110px 0 100px 0;
  background-color: #ffffff;
  color: rgba(52, 52, 51, 0.8);
  font-size: 16px;
  font-weight: 300;
  line-height: 1.38;

  &_catalog {
    background-color: #fafafa;
  }

  &__title {
    display: block;
    margin: 0 0 45px 0;
    color: #343433;
    font-size: 25px;
    font-weight: 700;
  }

  p {
    display: block;
    margin: 0 0 20px 0;
    color: rgba(52, 52, 51, 0.8);
    font-size: 16px;
    font-weight: 300;
    line-height: 1.38;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    display: block;
    margin: 0 0 20px 0;
    color: #343433;
    font-weight: 700;
  }

  h1 {
    font-weight: 300;
    font-size: 35px;
  }

  h2 {
    font-size: 25px;
  }

  h3 {
    font-size: 20px;
  }

  h4 {
    font-size: 18px;
  }

  h5 {
    font-size: 14px;
  }

  h6 {
    font-size: 12px;
  }

  ul {
    display: block;
    margin: 0 0 0 20px;
    padding: 0 0 20px 0;
    color: rgba(0, 0, 0, 0.8);
    font-size: 16px;
    font-weight: 300;
    line-height: 1.88;
  }

  a {
    color: #00b956;
    cursor: pointer;
    text-decoration: underline;
    transition: all 0.2s ease-out;

    &:hover {
      color: #00b956;
      text-decoration: none;
    }
  }
}

.t-footer {
  display: block;
  padding: 80px 0 30px 0;
  background-color: rgba(0, 0, 0, 0.87);

  &__title {
    display: block;
    margin: 0 0 27px 0;
    color: #ffffff;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.18px;
  }

  &__subtitle {
    display: block;
    margin: 0 0 10px 0;
    opacity: 0.8;
    color: #ffffff;
    font-size: 16px;
    font-weight: 300;
  }

  &__phone {
    display: flex;
    align-items: center;
    margin: 0 0 15px 0;
    color: rgba(255, 255, 255, 0.6);
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.14px;

    img,
    svg {
      display: inline-block;
      max-width: 22px;
      max-height: 22px;
      margin-right: 11px;
    }

    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;

    &:hover {
      color: #ffffff;
      text-decoration: none;
    }
  }

  &__adress {
    display: flex;
    align-items: center;
    margin: 0 0 15px 0;
    color: rgba(255, 255, 255, 0.6);
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.14px;

    img,
    svg {
      display: inline-block;
      max-width: 22px;
      max-height: 22px;
      margin-right: 11px;
    }

    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
  }

  &__email {
    display: flex;
    align-items: center;
    margin: 0 0 15px 0;
    color: rgba(255, 255, 255, 0.6);
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.14px;

    img,
    svg {
      display: inline-block;
      max-width: 22px;
      max-height: 22px;
      margin-right: 7px;
    }

    span {
      text-decoration: underline;
    }

    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;

    &:hover {
      color: #ffffff;
      text-decoration: none;

      span {
        text-decoration: none;
      }
    }
  }

  &__nav {
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: block;

      a {
        display: block;
        margin: 0;
        color: rgba(255, 255, 255, 0.6);
        font-size: 14px;
        font-weight: 300;
        line-height: 1.8;
        letter-spacing: 0.14px;
        cursor: pointer;
        text-decoration: none;
        transition: all 0.2s ease-out;

        &:hover {
          color: #ffffff;
          text-decoration: none;
        }
      }
    }
  }

  &__more {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-end;
    text-align: right;
  }

  &__pay {
    display: block;
    padding: 0 0 35px 0;

    img,
    svg {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__social {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;

    a {
      display: inline-block;
      max-width: 30px;
      max-height: 30px;
      margin: 0 0 5px 5px;
      cursor: pointer;
      text-decoration: none;
      transition: all 0.2s ease-out;

      &:hover {
        transform: scale(1.1);
        text-decoration: none;
      }
    }
  }

  &__bottom {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin: 90px 0 0 0;
    padding: 22px 0 0 0;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }

  &__copyright {
    display: block;
    margin: 0;
    opacity: 0.38;
    color: #ffffff;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.14px;
  }

  &__policy {
    display: block;
    margin: 0;
    opacity: 0.38;
    color: #ffffff;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.14px;
    cursor: pointer;
    text-decoration: underline;
    transition: all 0.2s ease-out;

    &:hover {
      opacity: 1;
      color: #ffffff;
      text-decoration: none;
    }
  }
}

.t-breadcrumps {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin: 0;
  padding: 28px 0;
  list-style: none;
  color: rgba(52, 52, 51, 0.6);
  font-size: 14px;
  font-weight: 300;

  img,
  svg {
    display: inline-block;
    max-width: 10px;
    max-height: 10px;
    margin: 0 10px;
  }

  a {
    color: #00b956;
    font-size: 14px;
    font-weight: 300;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;

    &:hover {
      color: #343433;
      text-decoration: none;
    }
  }
}

.t-stocks {
  display: block;
  padding: 35px 0 120px 0;

  &__title {
    display: block;
    margin: 0 0 20px 0;
    color: #343433;
    font-size: 25px;
    font-weight: 700;
  }

  &__sort {
    display: block;
    position: relative;
    border: 1px solid #e1e1e1;
    background-color: #ffffff;

    &:before {
      display: block;
      position: absolute;
      content: '';
      width: 17px;
      height: 20px;
      left: 15px;
      top: 50%;
      margin-top: -10px;
      background: url('../images/icon_sort.png') no-repeat center center;
      background-size: contain;
      pointer-events: none;
    }

    &:after {
      display: block;
      position: absolute;
      content: '';
      width: 12px;
      height: 8px;
      right: 15px;
      top: 50%;
      margin-top: -4px;
      background: url('../images/icon_arrow_down.png') no-repeat center center;
      background-size: contain;
      pointer-events: none;
    }

    select {
      display: block;
      width: 100%;
      padding: 15px 30px 15px 50px;
      border: 0;
      outline: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      color: rgba(52, 52, 51, 0.8);
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 0.16px;
    }
  }

  &__cards {
    display: block;
    padding: 23px 0 0 0;
  }

  &-card {
    display: block;
    width: 100%;
    max-width: 570px;
    margin: 0 auto 30px auto;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;

    &:hover {
      .t-stocks-card__arrow {
        background: #00b956;

        img,
        svg {
          filter: brightness(0.3);
        }
      }

      text-decoration: none;
    }

    &__image {
      display: block;
      width: 100%;
      height: 300px;
      background-position: center center;
      background-size: cover;
    }

    &__content {
      display: block;
      width: 100%;
      padding: 20px 25px 25px 15px;
      background-color: #343433;
    }

    &__date {
      display: block;
      margin: 0 0 15px 0;
      color: rgba(255, 255, 255, 0.7);
      font-size: 13px;
      font-weight: 300;
      letter-spacing: 0.13px;

      &_hot {
        position: relative;
        padding-left: 20px;
        color: #fed430;

        &:before {
          display: block;
          position: absolute;
          content: '';
          width: 16px;
          height: 20px;
          left: 0;
          margin-top: -2px;
          background: url('../images/icon_hot.png') no-repeat center center;
          background-size: contain;
        }
      }
    }

    &__title {
      display: block;
      margin: 0;
      color: #ffffff;
      font-size: 18px;
      font-weight: 400;
    }

    &__arrow {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      border: 1px solid #00b956;
      border-radius: 999rem;
      transition: all 0.2s ease-out;

      img,
      svg {
        display: block;
        max-width: 30px;
        max-height: 30px;
        transition: all 0.2s ease-out;
      }
    }
  }
}

.t-pagination {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  margin: 0;
  padding: 70px 0 0 0;
  list-style: none;

  &_active {
    a {
      background: #00b956;
      color: #fefefe !important;
    }
  }

  li {
    display: inline-block;

    a {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      min-width: 40px;
      height: 40px;
      padding: 2px;
      color: rgba(52, 52, 51, 0.7);
      font-size: 18px;
      font-weight: 300;
      text-align: center;
      cursor: pointer;
      text-decoration: none;
      transition: all 0.2s ease-out;

      &:hover {
        background: #ffffff;
        color: #00b956 !important;
        text-decoration: none;
      }
    }

    b,
    strong {
      margin: 0 15px;
      color: #343433;
      font-size: 18px;
      font-weight: 400;
    }
  }
}

.t-basket {
  display: block;

  &_show_mob {
    display: none;
  }

  &__title {
    display: block;
    margin: 0 0 50px 0;
    color: #343433;
    font-size: 25px;
    font-weight: 700;
  }

  &__subtitles {
    display: block;
    margin: 0 0 30px 0;
    padding: 8px 10px;
    border-bottom: 1px solid #d9d9d9;
    color: rgba(52, 52, 51, 0.7);
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.7px;
  }

  &__cards {
    display: block;
  }

  &-card {
    display: block;
    position: relative;
    width: 100%;
    margin: 0 0 5px 0;
    padding: 18px 20px;
    background-color: #fafafa;

    a {
      cursor: pointer;
      text-decoration: none;
      transition: all 0.2s ease-out;

      &:hover {

        h1,
        h2,
        h3,
        h4 {
          color: #00b956;
        }

        color: #00b956;
        text-decoration: none;
      }
    }

    &__title {
      display: block;
      margin: 0 0 8px 0;
      color: #343434;
      font-size: 18px;
      font-weight: 400;
      transition: all 0.2s ease-out;
    }

    &__category {
      display: block;
      margin: 0 0 20px 0;
      color: rgba(0, 0, 0, 0.3);
      font-size: 13px;
      font-weight: 300;
      letter-spacing: 0.13px;
    }

    &__image {
      display: block;
      max-width: 160px;
      height: 55px;

      img,
      svg {
        display: block;
        max-width: 100%;
        max-height: 100%;
      }
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      /* transform: scale(1.5); */
      height: 46px;
      opacity: 1;
    }

    &__counter {
      display: inline-block;
      padding: 16px 2px 16px 14px;
      border: 1px solid #e3e3e3;
      outline: none;
      background-color: #f5f5f5;
      color: rgba(52, 52, 52, 0.8);
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 0.35px;
    }

    &__price {
      display: block;
      margin: 8px 0;
      color: #343434;
      font-size: 20px;
      font-weight: 700;
    }

    &__delete {
      display: block;
      width: 14px;
      height: 14px;

      img,
      svg {
        max-width: 100%;
        max-height: 100%;
      }

      cursor: pointer;
      text-decoration: none;
      transition: all 0.2s ease-out;

      &:hover {
        transform: scale(1.1);
        text-decoration: none;
      }
    }
  }

  &__summary {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    align-items: center;
    padding: 5px 0 110px 0;
  }

  &__button {
    display: inline-block;
    padding: 20px 72px;
    background-color: #00b956;
    border: 0;
    outline: none;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.32px;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;

    &:hover {
      background-color: #25e780;
      color: #ffffff;
      text-decoration: none;
    }
  }

  &__summ {
    display: inline-block;
    margin: 10px 30px;
    color: rgba(52, 52, 51, 0.8);
    font-size: 16px;
    font-weight: 300;
    line-height: 1.38;

    b,
    strong {
      display: inline-block;
      color: #343433;
      font-size: 20px;
      font-weight: 700;
    }
  }
}

.t-order {
  display: block;
  padding: 120px 0 90px 0;
  background-color: #fafafa;

  &__title {
    display: block;
    margin: 0 0 16px 0;
    color: #343433;
    font-size: 25px;
    font-weight: 700;
  }

  &__text {
    display: block;
    margin: 0 0 50px 0;
    color: rgba(56, 56, 56, 0.6);
    font-size: 14px;
    font-weight: 300;
    line-height: 1.43;
    letter-spacing: 0.14px;
  }

  &__subtitle {
    display: block;
    margin: 0 0 10px 0;
    color: #343433;
    font-size: 18px;
    font-weight: 400;
  }

  &__wrap {
    display: block;
    padding: 20px 0 30px 0;
    border-top: 1px solid #d4d4d4;
  }

  &-choose {
    display: block;
    position: relative;
    width: 100%;
    max-width: 400px;
    margin: -10px 0 0 0;
    padding: 0;

    input {
      width: 0;
      height: 0;
      overflow: hidden;
      opacity: 0;

      &:checked~.t-order-choose__this {
        background: url('../images/icon_checkbox_checked.png') no-repeat 0 0;
      }
    }

    &__this {
      display: block;
      padding: 0 0 0 35px;
      background: url('../images/icon_checkbox.png') no-repeat 0 0;
      color: rgba(52, 52, 51, 0.8);
      font-size: 16px;
      font-weight: 300;
      line-height: 1.38;
      letter-spacing: 0.16px;

      a {
        color: #00b956;
        cursor: pointer;
        text-decoration: underline;
        transition: all 0.2s ease-out;

        &:hover {
          color: #00b956;
          text-decoration: none;
        }
      }
    }
  }

  &__drop {
    display: block;
    position: relative;
    margin: 0 0 10px 0;
    border: 1px solid #e0e0e0;
    background-color: #ffffff;

    &:after {
      display: block;
      position: absolute;
      content: '';
      width: 12px;
      height: 8px;
      right: 20px;
      top: 50%;
      margin-top: -4px;
      background: url('../images/icon_arrow_down.png') no-repeat center center;
      background-size: contain;
      pointer-events: none;
    }

    select {
      display: block;
      width: 100%;
      padding: 21px 30px 21px 24px;
      border: 0;
      outline: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      color: rgba(52, 52, 51, 0.9);
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 0.16px;
    }
  }

  &__input {
    display: block;
    position: relative;
    margin: 0 0 10px 0;

    &_p_l_5 {
      padding-left: 5px;
    }

    &_p_r_5 {
      padding-right: 5px;
    }

    label {
      display: block;
      position: absolute;
      top: 50%;
      left: 24px;
      margin: -9px 0 0 0;
      padding: 0;
      color: rgba(52, 52, 51, 0.35);
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 0.16px;
      pointer-events: none;
      z-index: 1;
      transition: all 0.2s ease-out;
    }

    input {
      display: block;
      width: 100%;
      border: 1px solid #e1e1e1;
      background-color: #ffffff;
      padding: 20px 24px;
      color: rgba(52, 52, 51, 0.8);
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 0.16px;
      outline: none;
      transition: all 0.2s ease-out;

      &:focus,
      &:valid {
        &~label {
          top: 0;
          color: rgba(52, 52, 51, 0.7);
          font-size: 14px;
          text-shadow: 0px 1px 0 #ffffff, 0px -1px 0 #ffffff, 1px 0px 0 #ffffff, -1px 0px 0 #ffffff, 1px 1px 0 #ffffff, 1px -1px 0 #ffffff, -1px 1px 0 #ffffff, -1px -1px 0 #ffffff, 0px 2px 0 #ffffff, 0px -2px 0 #ffffff, 2px 0px 0 #ffffff, -2px 0px 0 #ffffff, 2px 2px 0 #ffffff, 2px -2px 0 #ffffff, -2px 2px 0 #ffffff, -2px -2px 0 #ffffff;
        }
      }
    }
  }

  &__button {
    display: block;
    width: 100%;
    padding: 20px 12px;
    border: 0;
    outline: none;
    background-color: #00b956;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.32px;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;

    &:hover {
      background-color: #25e780;
      color: #ffffff;
      text-decoration: none;
    }
  }

  &__please {
    display: block;
    margin: 0;
    padding: 10px 0 20px 0;
    opacity: 0.8;
    color: #343433;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.38;
    letter-spacing: 0.16px;
  }

  &__info {
    display: flex;
    align-items: center;
    margin: 16px 0 0 0;
    color: rgba(52, 52, 51, 0.6);
    color: #343433;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.14px;

    img,
    svg {
      margin-right: 12px;
    }
  }
}

.t-filter {
  display: block;
  width: 100%;
  max-width: 270px;
  margin: 10px auto 20px auto;
  padding: 30px 20px 20px 20px;
  background-color: #343433;

  &__title {
    display: block;
    position: relative;
    margin: 0 0 12px 0;
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;

    &_collapse {
      padding-right: 20px;

      &:after {
        display: block;
        position: absolute;
        content: '';
        top: 5px;
        right: 0;
        width: 12px;
        height: 8px;
        background: url('../images/icon_collapse.png') no-repeat right top;
        background-size: contain;
        z-index: 1;
      }

      &_active {
        &:after {
          transform: rotate(180deg);
        }
      }

      cursor: pointer;
      text-decoration: none;
      transition: all 0.2s ease-out;

      &:hover {
        color: #00b956;
        text-decoration: none;
      }
    }
  }

  &__content {
    display: none;
    padding: 8px 0 30px 0;
  }

  &__group {
    display: block;
  }

  &__price {
    display: block;
    margin: 0;
    padding: 0 0 20px 0;
    color: #ffffff;
    font-size: 16px;
    font-weight: 300;
  }

  #price {
    margin-bottom: 36px;
  }

  .noUi-target {
    height: 3px;
    border: 0;
    outline: none;
    background: rgba(255, 255, 255, 0.38);
    border-radius: 0;
    box-shadow: none;
  }

  .noUi-connect {
    background: #00b956;
  }

  .noUi-horizontal .noUi-handle {
    width: 17px;
    height: 17px;
    top: -8px;
    border: 0;
    outline: none;
    border-radius: 999rem;
    background-color: #ffffff;

    &:before,
    &:after {
      display: none;
    }
  }

  &-check {
    display: block;
    position: relative;
    width: 100%;
    margin: -10px 0 5px 0;
    padding: 0;

    input {
      width: 0;
      height: 0;
      overflow: hidden;
      opacity: 0;

      &:checked~.t-filter-check__this {
        background: url('../images/filter_checkbox_checked.png') no-repeat 0 0;
      }
    }

    &__this {
      display: flex;
      justify-content: space-between;
      padding: 0 0 0 30px;
      background: url('../images/filter_checkbox.png') no-repeat 0 0;
      color: #ffffff;
      font-size: 16px;
      font-weight: 300;

      a {
        color: #00b956;
        cursor: pointer;
        text-decoration: underline;
        transition: all 0.2s ease-out;

        &:hover {
          color: #00b956;
          text-decoration: none;
        }
      }
    }

    &__counter {
      opacity: 0.38;
      color: #ffffff;
      font-size: 16px;
      font-weight: 300;
    }
  }

  &__button {
    display: block;
    width: 100%;
    margin: 20px 0 0 0;
    padding: 16px 12px;
    border: 1px solid #00b956;
    background: transparent;
    outline: none;
    color: rgba(0, 185, 86, 0.9);
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.32px;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;

    &:hover {
      background: #00b956;
      color: #ffffff;
      text-decoration: none;
    }
  }
}

.t-catalog {
  display: block;
  padding: 10px 0 100px 0;

  &__title {
    display: block;
    margin: 0 0 16px 0;
    color: #343433;
    font-size: 25px;
    font-weight: 700;
  }

  &__sort {
    display: block;
    position: relative;
    border: 1px solid #e1e1e1;
    background-color: #ffffff;

    &:before {
      display: block;
      position: absolute;
      content: '';
      width: 17px;
      height: 20px;
      left: 15px;
      top: 50%;
      margin-top: -10px;
      background: url('../images/icon_sort.png') no-repeat center center;
      background-size: contain;
      pointer-events: none;
    }

    &:after {
      display: block;
      position: absolute;
      content: '';
      width: 12px;
      height: 8px;
      right: 15px;
      top: 50%;
      margin-top: -4px;
      background: url('../images/icon_arrow_down.png') no-repeat center center;
      background-size: contain;
      pointer-events: none;
    }

    select {
      display: block;
      width: 100%;
      padding: 15px 30px 15px 50px;
      border: 0;
      outline: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      color: rgba(52, 52, 51, 0.8);
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 0.16px;
    }
  }

  &__cards {
    display: block;
    margin-top: 16px;
  }

  &-card {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1 1 270px;
    position: relative;
    min-height: 263px;
    margin: 0 auto 30px auto;
    padding: 22px 20px;
    background-color: #f4f4f4;
    overflow: hidden;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;

    &:after {
      display: block;
      position: absolute;
      content: attr(data-special);
      writing-mode: vertical-rl;
      text-orientation: mixed;
      transform: rotate(180deg);
      top: 0;
      right: -4px;
      height: 100%;
      opacity: 0.2;
      text-shadow: -1px 0 #343433, 0 1px #343433, 1px 0 #343433, 0 -1px #343433;
      color: #f4f4f4;
      font-size: 30px;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 0.3px;
      line-height: 1;
      text-align: center;
      z-index: 1;
    }

    &:hover {
      background: #343433;
      text-decoration: none;

      .t-catalog-card__title {
        color: #ffffff;
      }

      .t-catalog-card__category {
        color: #ffffff;
      }

      .t-catalog-card__price {
        color: #ffffff;
      }

      .t-catalog-card__oldprice {
        color: #ffffff;
      }
    }

    &__title {
      display: block;
      max-height: 38px;
      overflow: hidden;
      margin: 0;
      color: #343433;
      font-size: 16px;
      font-weight: 400;
    }

    &__category {
      display: block;
      max-height: 16px;
      overflow: hidden;
      margin: 6px 0 0 0;
      opacity: 0.5;
      color: #343433;
      font-size: 13px;
      font-weight: 300;
      letter-spacing: 0.13px;
    }

    &__image {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1 1 100%;
      width: 100%;
      height: 120px;
      padding: 5px;

      img,
      svg {
        max-width: 100%;
        max-height: 100%;
      }
    }

    &__price {
      display: block;
      margin: 0;
      color: #343433;
      font-size: 20px;
      font-weight: 700;
    }

    &__oldprice {
      display: block;
      margin: 0;
      opacity: 0.5;
      color: #343433;
      font-size: 14px;
      font-weight: 400;
      text-decoration: line-through;
    }
  }

  .t-pagination {
    padding-top: 20px;
  }
}

.t-item {
  display: block;
  padding: 20px 0 90px 0;

  &__images {
    display: block;
    position: relative;
    width: 100%;
    border: 1px solid #e1e1e1;
    background-color: #ffffff;
  }

  &__content {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    width: 100%;
    min-height: 450px;
    overflow: hidden;
    padding: 45px 30px 30px 30px;
    background-color: #343433;

    &>div {
      width: 100%;
    }

    &:after {
      display: block;
      position: absolute;
      content: attr(data-special);
      writing-mode: vertical-rl;
      text-orientation: mixed;
      transform: rotate(180deg);
      top: 0;
      right: -5px;
      height: 100%;
      opacity: 0.12;
      text-shadow: -1px 0 #ffffff, 0 1px #ffffff, 1px 0 #ffffff, 0 -1px #ffffff;
      color: #343433;
      font-size: 45px;
      font-weight: 400;
      letter-spacing: 0.45px;
      text-transform: uppercase;
      line-height: 1;
      text-align: center;
      z-index: 1;
    }
  }

  &__title {
    display: block;
    margin: 0;
    color: #ffffff;
    font-size: 25px;
    font-weight: 700;
  }

  &__category {
    display: block;
    margin: 10px 0;
    opacity: 0.4;
    color: #ffffff;
    font-size: 13px;
    font-weight: 300;
    letter-spacing: 0.13px;
  }

  &__price {
    display: block;
    margin: 7px 0;
    color: #ffffff;
    font-size: 25px;
    font-weight: 700;
  }

  &__oldprice {
    display: block;
    margin: 0;
    opacity: 0.5;
    color: #ffffff;
    font-size: 18px;
    font-weight: 400;
    text-decoration: line-through;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    height: 46px;
    /* transform: scale(1.5); */
    opacity: 0.4;
  }

  &__counter {
    display: inline-block;
    padding: 16px 2px 16px 14px;
    border: 1px solid #686868;
    outline: none;
    background-color: #424242;
    color: rgba(255, 255, 255, 0.24);
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.35px;
  }

  &__button {
    display: inline-block;
    padding: 16px 48px;
    background-color: #00b956;
    border: 0;
    outline: none;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.32px;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;

    &:hover {
      background-color: #25e780;
      color: #ffffff;
      text-decoration: none;
    }
  }

  &__consult {
    display: inline-block;
    padding: 16px 29px;
    background-color: transparent;
    border: 1px solid #fed430;
    outline: none;
    color: #fed430;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.32px;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;

    &:hover {
      background-color: #d3ad15;
      color: #ffffff;
      text-decoration: none;
    }
  }

  &__info {
    display: flex;
    align-items: center;
    margin: 8px 0;

    &-title {
      margin-right: 6px;
      color: #9e9e9e;
      font-size: 14px;
      font-weight: 500;
    }

    &-text {
      color: #ffffff;
      font-size: 14px;
      font-weight: 500;
    }
  }

  &__icon {
    display: inline-block;
    width: 18px;
    height: 18px;
    margin-right: 8px;

    img,
    svg {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__row {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    max-width: 490px;
  }

  &__big {
    display: block;

    &-card {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 355px;
      padding: 20px;
      background: #ffffff;
      transition: all 0.2s ease-out;

      img,
      svg {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  &__small {
    display: block;

    &-card {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 100px;
      height: 100px;
      padding: 10px;
      background: #f4f4f4;
      transition: all 0.2s ease-out;

      img,
      svg {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .swiper-slide {
      max-width: 100px;
    }
  }

  .swiper-slide-thumb-active {
    .t-item__small-card {
      background: #ffffff;
    }
  }

  &__brand {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    max-width: 100px;
    height: 100px;
    pointer-events: none;
    z-index: 3;

    img,
    svg {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.t-tabs {
  display: block;
  position: relative;
  width: 100%;

  &__header {
    display: flex;
    flex-flow: row wrap;
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;

    &:after {
      display: block;
      position: absolute;
      content: '';
      width: 100%;
      left: 0;
      height: 1px;
      bottom: 0px;
      background: #d8dce5;
    }

    li {
      display: inline-block;
      padding: 12px 20px;
      border-bottom: 2px solid transparent;
      color: rgba(52, 52, 51, 0.7);
      font-size: 14px;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 0.7px;
      z-index: 1;

      &.current {
        border-color: #00b956;
        color: #00b956;
      }

      cursor: pointer;
      text-decoration: none;
      transition: all 0.2s ease-out;

      &:hover {
        color: #00b956;
        text-decoration: none;
      }
    }
  }

  &__content {
    display: none;
    position: relative;
    width: 100%;
    max-width: 770px;
    min-height: 400px;
    padding: 50px 0;
    color: rgba(41, 41, 41, 0.8);
    font-size: 16px;
    font-weight: 300;
    line-height: 1.38;
    letter-spacing: 0.16px;

    p {
      margin: 0 0 20px 0;
    }

    &.current {
      display: block;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      display: block;
      margin: 0 0 20px 0;
      color: #343433;
      font-weight: 700;
    }

    h1 {
      font-weight: 300;
      font-size: 35px;
    }

    h2 {
      font-size: 25px;
    }

    h3 {
      font-size: 20px;
    }

    h4 {
      font-size: 18px;
    }

    h5 {
      font-size: 14px;
    }

    h6 {
      font-size: 12px;
    }

    ul {
      display: block;
      margin: 0 0 0 20px;
      padding: 0 0 20px 0;
      color: rgba(0, 0, 0, 0.8);
      font-size: 16px;
      font-weight: 300;
      line-height: 1.88;
    }

    a {
      color: #00b956;
      cursor: pointer;
      text-decoration: underline;
      transition: all 0.2s ease-out;

      &:hover {
        color: #00b956;
        text-decoration: none;
      }
    }
  }
}

.t-similar {
  display: block;
  padding: 80px 0 90px 0;
  background: #ffffff;

  &__title {
    display: block;
    margin: 0 0 36px 0;
    color: #343433;
    font-size: 25px;
    font-weight: 700;
  }
}

.t-fast-form {
  display: block;
  position: relative;
  width: 100%;
  max-width: 470px;
  padding: 35px 30px 30px 30px;
  background-color: #f4f4f4;

  &__title {
    display: block;
    margin: 0 0 26px 0;
    color: #343433;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
  }

  &__input {
    display: block;
    position: relative;
    margin: 0 0 10px 0;

    label {
      display: block;
      position: absolute;
      top: 50%;
      left: 24px;
      margin: -9px 0 0 0;
      padding: 0;
      color: rgba(52, 52, 51, 0.35);
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 0.16px;
      pointer-events: none;
      z-index: 1;
      transition: all 0.2s ease-out;
    }

    input {
      display: block;
      width: 100%;
      border: 1px solid #e1e1e1;
      background-color: #ffffff;
      padding: 20px 24px;
      color: rgba(52, 52, 51, 0.8);
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 0.16px;
      outline: none;
      transition: all 0.2s ease-out;

      &:focus,
      &:valid {
        &~label {
          top: 0;
          color: rgba(52, 52, 51, 0.7);
          font-size: 14px;
          text-shadow: 0px 1px 0 #ffffff, 0px -1px 0 #ffffff, 1px 0px 0 #ffffff, -1px 0px 0 #ffffff, 1px 1px 0 #ffffff, 1px -1px 0 #ffffff, -1px 1px 0 #ffffff, -1px -1px 0 #ffffff, 0px 2px 0 #ffffff, 0px -2px 0 #ffffff, 2px 0px 0 #ffffff, -2px 0px 0 #ffffff, 2px 2px 0 #ffffff, 2px -2px 0 #ffffff, -2px 2px 0 #ffffff, -2px -2px 0 #ffffff;
        }
      }
    }
  }

  &__button {
    display: block;
    width: 100%;
    padding: 20px 12px;
    border: 0;
    outline: none;
    background-color: #00b956;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.32px;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;

    &:hover {
      background-color: #25e780;
      color: #ffffff;
      text-decoration: none;
    }
  }

  &__info {
    display: flex;
    align-items: center;
    margin: 18px 0 0 0;
    color: rgba(52, 52, 51, 0.6);
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.14px;

    img,
    svg {
      margin-right: 15px;
    }
  }
}

.t-article {
  display: block;
  padding: 20px 0 105px 0;

  &__welcome {
    display: block;
    width: 100%;
    background-position: center center;
    background-size: cover;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;

    &:hover {
      .t-article__welcome-content {
        background: rgba(0, 0, 0, 0.8);
      }

      text-decoration: none;
    }

    &-content {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
      max-width: 585px;
      min-height: 400px;
      padding: 30px 50px 30px 50px;
      background: rgba(0, 0, 0, 0.6);
      transition: all 0.2s ease-out;
    }

    &-date {
      display: block;
      margin: 0 0 20px 0;
      opacity: 0.7;
      color: #ffffff;
      font-size: 13px;
      font-weight: 300;
      letter-spacing: 0.13px;
    }

    &-title {
      display: block;
      margin: 0;
      color: #ffffff;
      font-size: 25px;
      font-weight: 700;
    }

    &-link {
      display: inline-flex;
      align-items: center;
      margin: 45px 0 0 0;
      color: #00d965;
      font-size: 16px;
      font-weight: 400;

      img,
      svg {
        max-width: 15px;
        max-height: 15px;
        margin-left: 10px;
      }
    }
  }

  &__wrap {
    display: block;
    padding: 70px 0 0 0;
  }

  &__main {
    display: block;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      display: block;
      margin: 0 0 20px 0;
      color: #343433;
      font-weight: 700;
    }

    h1 {
      font-weight: 300;
      font-size: 35px;
    }

    h2 {
      font-size: 25px;
    }

    h3 {
      font-size: 20px;
    }

    h4 {
      font-size: 18px;
    }

    h5 {
      font-size: 14px;
    }

    h6 {
      font-size: 12px;
    }

    ul {
      display: block;
      margin: 0 0 0 20px;
      padding: 0 0 20px 0;
      color: rgba(0, 0, 0, 0.8);
      font-size: 16px;
      font-weight: 300;
      line-height: 1.88;
    }

    p {
      display: block;
      margin: 0 0 25px;
      color: rgba(52, 52, 52, 0.8);
      font-size: 16px;
      font-weight: 300;
      line-height: 1.38;
    }

    a {
      color: #00b956;
      cursor: pointer;
      text-decoration: underline;
      transition: all 0.2s ease-out;

      &:hover {
        color: #00b956;
        text-decoration: none;
      }
    }
  }

  &__content {
    display: block;
    position: relative;
    width: 100%;
    padding: 110px 0 90px 0;
    background-color: #fafafa;
    color: rgba(52, 52, 52, 0.8);
    font-size: 16px;
    font-weight: 300;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      display: block;
      margin: 0 0 20px 0;
      color: #343433;
      font-weight: 700;
    }

    h1 {
      font-weight: 300;
      font-size: 35px;
    }

    h2 {
      font-size: 25px;
    }

    h3 {
      font-size: 20px;
    }

    h4 {
      font-size: 18px;
    }

    h5 {
      font-size: 14px;
    }

    h6 {
      font-size: 12px;
    }

    ul {
      display: block;
      margin: 0 0 0 20px;
      padding: 0 0 20px 0;
      color: rgba(0, 0, 0, 0.8);
      font-size: 16px;
      font-weight: 300;
      line-height: 1.88;
    }

    p {
      display: block;
      margin: 0 0 25px;
      color: rgba(52, 52, 52, 0.8);
      font-size: 16px;
      font-weight: 300;
      line-height: 1.38;
    }

    a {
      color: #00b956;
      cursor: pointer;
      text-decoration: underline;
      transition: all 0.2s ease-out;

      &:hover {
        color: #00b956;
        text-decoration: none;
      }
    }

    .table-hover tbody tr:hover {
      background-color: rgba(0, 185, 86, 0.1);
      color: #343433;
    }

    .table td,
    .table th {
      padding: 15px 20px;
      border: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      color: rgba(52, 52, 51, 0.8);
      font-size: 16px;
      font-weight: 300;
    }

    .table thead th {
      padding: 22px 20px;
      border: 0;
      background-color: #f4f4f4;
      color: rgba(52, 52, 51, 0.9);
      font-size: 16px;
      font-weight: 700;
    }

    figure {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 100%;
      max-width: 370px;
      height: 250px;
      margin: 0 0 30px 0;
      background: #f4f4f4;
      cursor: pointer;
      text-decoration: none;
      transition: all 0.2s ease-out;

      &:hover {

        img,
        svg {
          filter: brightness(0.5);
        }

        text-decoration: none;
      }

      a {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;
        height: 100%;
      }

      img,
      svg {
        max-width: 100%;
        max-height: 100%;
        transition: all 0.2s ease-out;
      }

      figcaption {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 16px 18px;
        background: rgba(0, 0, 0, 0.8);
        color: #ffffff;
        font-size: 14px;
        font-weight: 400;
        transition: all 0.2s ease-out;
      }
    }
  }
}

.t-about {
  display: block;
  padding: 20px 0;

  &__image {
    display: block;
    width: 100%;
    height: 500px;
    background-position: center center;
    background-size: cover;
  }

  &__wrap {
    display: block;
    position: relative;
    width: 100%;
    margin: -200px 0 0 0;
    padding: 60px 50px 20px 50px;
    background: #ffffff;
    z-index: 1;
  }

  &__title {
    display: block;
    margin: 0 0 36px 0;
    color: #343433;
    font-size: 25px;
    font-weight: 700;
  }

  &__prep {
    display: block;
    max-width: 740px;
    margin: 0 0 85px 0;
    color: #343433;
    font-size: 26px;
    font-weight: 300;
    line-height: 1.54;
  }

  &__blockquote {
    display: flex;
    width: 100%;
    margin: 60px 0 20px 0;
    padding: 0 0 0 20px;
    border-left: 1px solid #00b956;

    &-left {
      display: inline-block;
      max-width: 270px;
      padding-right: 60px;
    }

    &-image {
      display: block;
      width: 200px;
      height: 200px;
      border-radius: 999rem;
      background-position: center center;
      background-size: cover;
    }

    &-name {
      display: block;
      margin: 17px 0 8px 0;
      color: #343433;
      font-size: 18px;
      font-weight: 400;
    }

    &-who {
      display: block;
      margin: 0;
      opacity: 0.6;
      color: #343433;
      font-size: 14px;
      font-weight: 300;
    }

    &-text {
      display: inline-block;
      max-width: 785px;
      margin: 0;
      color: #343433;
      font-size: 26px;
      font-weight: 500;
      line-height: 1.54;
    }
  }

  &__content {
    display: block;
    position: relative;
    width: 100%;
    max-width: 680px;
    color: rgba(52, 52, 52, 0.8);
    font-size: 16px;
    font-weight: 300;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      display: block;
      margin: 0 0 20px 0;
      color: #343433;
      font-weight: 700;
    }

    h1 {
      font-weight: 300;
      font-size: 35px;
    }

    h2 {
      font-size: 25px;
    }

    h3 {
      font-size: 20px;
    }

    h4 {
      font-size: 18px;
    }

    h5 {
      font-size: 14px;
    }

    h6 {
      font-size: 12px;
    }

    ul {
      display: block;
      margin: 0 0 0 20px;
      padding: 0 0 20px 0;
      color: rgba(0, 0, 0, 0.8);
      font-size: 16px;
      font-weight: 300;
      line-height: 1.88;
    }

    p {
      display: block;
      margin: 0 0 25px;
      color: rgba(52, 52, 52, 0.8);
      font-size: 16px;
      font-weight: 300;
      line-height: 1.38;
    }

    a {
      color: #00b956;
      cursor: pointer;
      text-decoration: underline;
      transition: all 0.2s ease-out;

      &:hover {
        color: #00b956;
        text-decoration: none;
      }
    }

    .table-hover tbody tr:hover {
      background-color: rgba(0, 185, 86, 0.1);
      color: #343433;
    }

    .table td,
    .table th {
      padding: 15px 20px;
      border: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      color: rgba(52, 52, 51, 0.8);
      font-size: 16px;
      font-weight: 300;
    }

    .table thead th {
      padding: 22px 20px;
      border: 0;
      background-color: #f4f4f4;
      color: rgba(52, 52, 51, 0.9);
      font-size: 16px;
      font-weight: 700;
    }

    figure {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 100%;
      max-width: 370px;
      height: 250px;
      margin: 0 0 30px 0;
      background: #f4f4f4;

      img,
      svg {
        max-width: 100%;
        max-height: 100%;
      }

      figcaption {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 16px 18px;
        background: rgba(0, 0, 0, 0.8);
        color: #ffffff;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}

.t-gallery {
  display: block;
  padding: 120px 0 10px 0;
  position: relative;

  &__title {
    display: block;
    margin: 0 0 150px 0;
    color: #343433;
    font-size: 25px;
    font-weight: 700;
  }

  &__cards {
    display: block;
    position: relative;
    width: 100%;
    height: 525px;
    overflow: visible;
  }

  &__card {
    width: 100%;
    max-width: 500px;
    height: 365px;
    background-size: cover;
    background-position: center center;
    opacity: 0.3;
    transition: all 0.2s ease-out;
  }

  .swiper-slide-active {
    max-width: 719px;
    height: 525px;
    margin-top: -110px;
    opacity: 1;
  }

  .swiper-button-next,
  .swiper-button-prev {
    top: -110px;
    width: 60px;
    height: 60px;
    margin: 0;
    outline: none;
    background: transparent;

    &:after {
      display: none;
    }

    cursor: pointer;
    text-decoration: underline;
    transition: all 0.2s ease-out;

    &:hover {
      transform: scale(0.9);
      text-decoration: none;
    }
  }

  .swiper-button-next {
    left: auto;
    right: 0;
    background: url('../images/swiper_next.png') no-repeat center center;
    background-size: contain;
  }

  .swiper-button-prev {
    left: auto;
    right: 70px;
    background: url('../images/swiper_prev.png') no-repeat center center;
    background-size: contain;
  }
}


.t-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  z-index: 100;

  &__centered {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    background: rgba(0, 0, 0, 0.8);
  }

  &__close {
    display: block;
    position: absolute;
    right: 0px;
    top: 0px;
    padding: 10px;
    z-index: 1;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;

    &:hover,
    &:active,
    &:focus {
      opacity: 0.7;
      text-decoration: none;
    }
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

@media (max-width: 1199px) {
  .t-consult__image {
    max-width: 100%;
    height: auto;
    max-height: 100%;
  }

  .t-header__mob {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
    padding: 12px 0;
  }

  .t-header__toggle {
    display: inline-block;
    padding: 8px 0 8px 8px;

    img,
    svg {
      max-width: 42px;
      max-height: 42px;
    }

    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;

    &:hover {
      transform: scale(1.1);
      text-decoration: none;
    }
  }

  .t-top__item img,
  .t-top__item svg {
    margin: 4px 8px 4px 0;
  }

  .t-top {
    padding: 2px 0;
  }

  .t-header__nav {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: flex-start;
    width: 100%;
  }

  .t-header__main {
    justify-content: space-around;
  }

  .t-header__subnav {
    display: block;
    width: 100%;
    padding: 6px 0;
    text-align: center;

    li {
      display: block;
      width: 100%;

      a {
        display: block;
        width: 100%;
        padding: 12px;
      }
    }
  }

  .t-header__right {
    justify-content: space-around;
  }

  .t-header__more:before {
    display: none;
  }

  .t-header__more:after {
    display: none;
  }

  .t-top {
    display: none;
  }

  .t-header__main {
    display: none;
  }

  .t-header__right {
    display: none;
  }

  .t-home-carousel-card__content {
    margin-bottom: 20px;
    padding: 30px 20px;
  }

  .t-home-carousel-card__title {
    margin: 0 0 20px 0;
    font-size: 22px;
  }

  .t-home-carousel-card__content {
    max-height: 470px;
    overflow-y: auto;
  }

  .t-home-carousel .swiper-pagination {
    left: auto;
    right: 0;
    background: rgba(0, 0, 0, 0.4);
  }

  .t-home-wrap {
    padding: 30px 0;
  }

  .t-popular__header-button {
    padding: 12px 8px;
  }

  .t-popular__header {
    padding: 12px 8px;
  }

  .t-popular__header-title {
    margin: 12px 0;
  }

  .t-popular-card {
    box-shadow: inset 0 0 1px #000000;
  }

  .t-popular {
    margin-bottom: 30px;
  }

  .t-services__header-button {
    padding: 12px 8px;
  }

  .t-services__header {
    padding: 12px 8px;
  }

  .t-services__header-title {
    margin: 12px 0;
  }

  .t-services-card__content {
    padding: 30px 20px;
  }

  .t-services {
    margin-bottom: 30px;
  }

  .t-features__cards {
    justify-content: center;
  }

  .t-timeline {
    padding: 0 0 30px 0;
  }

  .t-timeline-card {
    flex-flow: row nowrap;
    position: relative;

    &:first-child {
      flex-flow: row wrap;

      .t-timeline-card__wrap {
        padding-left: 50px !important;
      }

      .t-timeline-card__number {
        position: absolute;
        left: 0;
        height: 100%;
      }
    }
  }

  .t-timeline-card__number {
    max-width: 30px;
    min-height: 30px;
    font-size: 20px;
    z-index: 1;
    order: -1;
  }

  .t-timeline-card__content {
    order: 1;
  }

  .t-timeline-card__icon {
    order: 2;
  }

  .t-timeline-card__wrap {
    padding: 30px 20px !important;
  }

  .t-partners {
    padding: 30px 0;
  }

  .t-partners__title {
    margin-bottom: 20px;
    font-size: 32px;
    text-align: center;
  }

  .t-partners__cards {
    justify-content: center;
  }

  .t-consult__title {
    margin: 20px 0;
  }

  .t-consult__input {
    margin-bottom: 20px;
  }

  .t-consult__info {
    margin: 15px 0;
  }

  .t-home-seo {
    padding: 30px 0;
  }

  .t-home-seo__title {
    margin-bottom: 20px;
  }

  .t-footer {
    padding: 20px 0;
  }

  .t-footer__title {
    margin: 20px 0;
  }

  .t-footer__more {
    align-items: flex-start;
    padding: 20px 0;
  }

  .t-footer__bottom {
    margin: 0;
    padding-top: 20px;
  }

  .t-stocks {
    padding: 0 0 30px 0;
  }

  .t-stocks-card__image {
    height: 200px;
  }

  .t-stocks-card__content {
    padding: 30px 20px;

    .col-2 {
      padding: 0;
    }
  }

  .t-pagination {
    padding-top: 20px;
  }

  .t-basket__title {
    margin-bottom: 20px;
  }

  .t-basket__subtitles {
    display: none;
  }

  .t-basket_show_mob {
    display: inline-block;
    margin: 6px 12px;
    opacity: 0.7;
    color: #343433;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.7px;
  }

  .t-basket-card__price {
    display: inline-block;
  }

  .t-basket-card__delete {
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
    max-width: 24px;
    height: auto;
    max-height: 24px;
    padding: 6px;
    z-index: 1;
  }

  .t-basket-card__image {
    max-width: 100%;
    height: auto;
  }

  .t-basket-card__counter {
    margin: 20px 0;
  }

  .t-basket__summary {
    justify-content: space-around;
  }

  .t-basket__summary {
    padding: 10px 0;
  }

  .t-order {
    padding: 30px 0;
  }

  .t-order__text {
    margin-bottom: 30px;
  }

  .t-order__wrap {
    padding: 15px 0;
  }

  .t-header__more {
    width: 100%;
  }

  .t-catalog {
    padding: 0 0 30px 0;
  }

  .t-item__big-card {
    height: 260px;
  }

  .t-item__content {
    min-height: auto;
    padding: 30px 25px;
  }

  .t-item__content:after {
    font-size: 28px;
  }

  .t-item__row {
    justify-content: flex-start;
    margin-top: 10px;
  }

  .t-item__button {
    padding: 16px 57px;
  }

  .t-item {
    padding: 0 0 30px 0;
  }

  .t-tabs__header {
    justify-content: space-around;
  }

  .t-tabs__content {
    min-height: auto;
    padding: 20px 0;
    font-size: 14px;
  }

  .t-similar {
    padding: 30px 0;
  }

  .t-similar__title {
    margin-bottom: 30px;
  }

  .t-article__wrap {
    padding-top: 20px;
  }

  .t-article {
    padding: 20px 0;
  }

  .t-article__content {
    padding: 20px 0;
  }

  .t-article__content figure {
    margin-bottom: 10px;
  }

  .t-gallery__cards {
    overflow: hidden;
  }

  .t-about__blockquote {
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: flex-start;
    margin: 20px 0;
    padding: 30px 10px;
    text-align: center;
  }

  .t-about__wrap {
    margin: 0;
    padding: 30px 0 0 0;
  }

  .t-about__blockquote-left {
    padding: 10px;
  }

  .t-about__blockquote-text {
    font-size: 15px;
  }

  .t-gallery {
    padding: 20px 0;
  }

  .t-about__image {
    height: 200px;
  }

  .t-about__title {
    margin-bottom: 20px;
    font-size: 42px;
  }

  .t-about__prep {
    margin-bottom: 20px;
    font-size: 18px;
  }

  .t-gallery__title {
    margin-bottom: 20px;
  }

  .t-gallery__card {
    max-width: 100%;
    height: 240px;
  }

  .t-gallery .swiper-slide-active {
    max-width: 100%;
    height: 240px;
    margin: 0;
  }

  .t-gallery__cards {
    height: auto;
  }

  .t-gallery .swiper-button-next,
  .t-gallery .swiper-button-prev {
    top: auto;
    bottom: 0;
    opacity: 0.8;
  }
}