.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("../fonts/SFPro/OpenType/SF-Pro-Display-Regular.otf");
  font-weight: 400;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("../fonts/SFPro/OpenType/SF-Pro-Display-Light.otf");
  font-weight: 300;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("../fonts/SFPro/OpenType/SF-Pro-Display-Bold.otf");
  font-weight: 700;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("../fonts/SFPro/OpenType/SF-Pro-Display-Semibold.otf");
  font-weight: 600;
}

body {
  overflow-x: hidden;
  color: #000000;
  font-family: "SF Pro Display", Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.2;
}

.t-top {
  display: block;
  padding: 8px 0;
  background-color: #343433;
}

.t-top > .container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}

.t-top__item {
  display: inline-flex;
  align-items: center;
  margin: 0;
  opacity: 0.8;
  color: #fefefe;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.43;
  letter-spacing: 0.14px;
}

.t-top__item img,
.t-top__item svg {
  display: inline-block;
  margin-right: 8px;
}

.t-top__item_link {
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.t-top__item_link:hover {
  opacity: 1;
  color: #ffffff;
  text-decoration: none;
}

.t-header {
  display: block;
  padding: 0;
  background: #ffffff;
}

.t-header__toggle {
  display: none;
}

.t-header__logo {
  display: inline-block;
  max-width: 150px;
  max-height: 55px;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.t-header__logo img,
.t-header__logo svg {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

.t-header__logo:hover {
  transform: scale(0.9);
  color: #ffffff;
  text-decoration: none;
}

.t-header__main {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 0;
}

.t-header__nav {
  display: inline-block;
  margin: 0;
  padding: 0;
  list-style: none;
}

.t-header__nav li {
  display: inline-block;
}

.t-header__nav li a {
  display: inline-block;
  padding: 8px 11px;
  color: #343433;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.6px;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.t-header__nav li a:hover {
  color: #00b956;
  text-decoration: none;
}

.t-header__info {
  display: inline-block;
  margin: 0;
  padding: 6px 18px;
  border-left: 1px solid rgba(52, 52, 51, 0.3);
  border-right: 1px solid rgba(52, 52, 51, 0.3);
  opacity: 0.8;
  color: #343433;
  font-size: 12px;
  font-weight: 300;
  line-height: 1.4;
  letter-spacing: 0.6px;
}

.t-header__phone {
  display: inline-block;
  margin-left: 14px;
  color: #00b956;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.t-header__phone:hover {
  color: #343433;
  text-decoration: none;
}

.t-header__right {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  align-items: flex-start;
}

.t-header__more {
  display: inline-flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 10px 0;
  background-color: #343433;
}

.t-header__more:before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 100%;
  width: 0;
  height: 0;
  border-bottom: solid 62px #343433;
  border-left: solid 62px transparent;
}

.t-header__more:after {
  display: block;
  position: absolute;
  content: '';
  left: 100%;
  top: 0;
  width: calc((100vw - 1170px) / 2);
  height: 100%;
  background-color: #343433;
}

.t-header__dropper {
  position: relative;
}

.t-header__dropper:hover .t-header__drop {
  display: block;
}

.t-header__drop {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  max-width: 300px;
  margin: 0;
  padding: 18px 0;
  background-color: #343433;
  list-style: none;
  z-index: 4;
}

.t-header__drop li {
  display: inline-block;
}

.t-header__drop li a {
  display: inline-block;
  padding: 6px 15px;
  color: #ffffff;
  font-size: 14px;
  opacity: 0.7;
  font-weight: 500;
  letter-spacing: 0.6px;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.t-header__drop li a:hover {
  opacity: 1;
  color: #ffffff;
  text-decoration: none;
}

.t-header__subnav {
  display: inline-block;
  margin: 0;
  padding: 0;
  list-style: none;
}

.t-header__subnav > li {
  display: inline-block;
}

.t-header__subnav > li > a {
  display: inline-block;
  padding: 8px 15px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.6px;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.t-header__subnav > li > a:hover {
  color: #00b956;
  text-decoration: none;
}

.t-header__buttons {
  display: inline-flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
}

.t-header__search {
  display: inline-block;
  padding: 10px;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.t-header__search img,
.t-header__search svg {
  max-width: 22px;
  max-height: 22px;
}

.t-header__search:hover {
  opacity: 0.8;
  color: #ffffff;
  text-decoration: none;
}

.t-header__basket {
  display: inline-block;
  position: relative;
  padding: 10px;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.t-header__basket-counter {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 22px;
  height: 22px;
  padding: 0;
  overflow: hidden;
  border-radius: 999rem;
  background: #00b956;
  color: #ffffff;
  font-size: 12px;
  font-weight: 300;
  text-align: center;
  opacity: 0.9;
}

.t-header__basket img,
.t-header__basket svg {
  max-width: 22px;
  max-height: 22px;
}

.t-header__basket:hover {
  opacity: 0.8;
  color: #ffffff;
  text-decoration: none;
}

.t-header__call {
  display: inline-block;
  margin-left: 15px;
  padding: 13px 27px;
  border-radius: 2px;
  background-color: #00b956;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.28px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.t-header__call:hover {
  background-color: #25e780;
  color: #ffffff;
  text-decoration: none;
}

.t-home-carousel {
  display: block;
  position: relative;
}

.t-home-carousel .swiper-pagination {
  display: inline-flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  left: calc((100vw - 1200px) / 2 + 1170px);
  width: 15px;
  height: 100%;
  bottom: 0;
}

.t-home-carousel .swiper-pagination-clickable .swiper-pagination-bullet {
  margin: 6px 4px;
  width: 8px;
  height: 8px;
  border: 0;
  opacity: 1;
  background-color: #ffffff;
  transition: all 0.2s ease-out;
}

.t-home-carousel .swiper-pagination-bullet-active {
  width: 13px !important;
  height: 13px !important;
  border: 1px solid #ffffff !important;
  background: transparent !important;
}

.t-home-carousel-card {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  align-items: flex-start;
  min-height: 510px;
  background-position: center center;
  background-size: cover;
}

.t-home-carousel-card__content {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  align-items: flex-start;
  position: relative;
  width: 100%;
  max-width: 725px;
  margin: -267px 0 0 0;
  height: 267px;
  overflow-y: auto;
  padding: 40px 50px 0 50px;
  background: #ffffff url("../images/home_bg.png") repeat 0 0;
  z-index: 3;
}

.t-home-carousel-card__title {
  display: block;
  height: 126px;
  overflow: hidden;
  margin: 0 0 42px 0;
  color: #343433;
  font-size: 35px;
  font-weight: 300;
}

.t-home-carousel-card__button {
  display: inline-block;
  padding: 20px 74px;
  background-color: #00b956;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.32px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.t-home-carousel-card__button:hover {
  background-color: #25e780;
  color: #ffffff;
  text-decoration: none;
}

.t-home-wrap {
  padding: 170px 0 120px 0;
  background: url("../images/home_bg.png") repeat calc((100vw - 1200px) / 2) 0;
}

.t-popular {
  display: block;
  margin: 0 0 170px 0;
}

.t-popular__header {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 40px;
  background-color: #0d0d0d;
}

.t-popular__header-title {
  display: inline-block;
  margin: 20px 0;
  color: #ffffff;
  font-size: 25px;
  font-weight: 700;
}

.t-popular__header-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 58px 30px 54px 32px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.16px;
  background-color: rgba(52, 52, 51, 0.8);
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.t-popular__header-button img,
.t-popular__header-button svg {
  margin-left: 10px;
}

.t-popular__header-button:hover {
  background-color: #00b956;
  color: #ffffff;
  text-decoration: none;
}

.t-popular__cards {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: stretch;
}

.t-popular-card {
  display: inline-flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 1 390px;
  min-height: 380px;
  padding: 35px 40px;
  background-color: #f4f4f4;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.t-popular-card:hover {
  transform: scale(1.025);
  text-decoration: none;
}

.t-popular-card:nth-child(1) {
  flex: 1 1 780px;
  background: #343433;
}

.t-popular-card:nth-child(1) .t-popular-card__title {
  color: #ffffff;
}

.t-popular-card:nth-child(1) .t-popular-card__category {
  color: #ffffff;
}

.t-popular-card:nth-child(1) .t-popular-card__price {
  color: #ffffff;
}

.t-popular-card:nth-child(1) .t-popular-card__oldprice {
  color: #ffffff;
}

.t-popular-card:nth-child(3) {
  flex: 1 1 585px;
}

.t-popular-card:nth-child(4) {
  flex: 1 1 585px;
  background: #00b956;
}

.t-popular-card:nth-child(4) .t-popular-card__title {
  color: #ffffff;
}

.t-popular-card:nth-child(4) .t-popular-card__category {
  color: #ffffff;
}

.t-popular-card:nth-child(4) .t-popular-card__price {
  color: #ffffff;
}

.t-popular-card:nth-child(4) .t-popular-card__oldprice {
  color: #ffffff;
}

.t-popular-card:nth-child(5) {
  background: #343433;
}

.t-popular-card:nth-child(5) .t-popular-card__title {
  color: #ffffff;
}

.t-popular-card:nth-child(5) .t-popular-card__category {
  color: #ffffff;
}

.t-popular-card:nth-child(5) .t-popular-card__price {
  color: #ffffff;
}

.t-popular-card:nth-child(5) .t-popular-card__oldprice {
  color: #ffffff;
}

.t-popular-card:nth-child(6) {
  background: #ffffff;
}

.t-popular-card__title {
  display: block;
  margin: 0;
  color: #343433;
  font-size: 18px;
  font-weight: 400;
}

.t-popular-card__category {
  display: block;
  margin: 12px 0 0 0;
  opacity: 0.4;
  color: #000000;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0.13px;
}

.t-popular-card__image {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 100%;
  width: 100%;
  min-height: 240px;
}

.t-popular-card__image img,
.t-popular-card__image svg {
  max-width: 100%;
  max-height: 100%;
}

.t-popular-card__price {
  display: block;
  margin: 0;
  color: #343433;
  font-size: 20px;
  font-weight: 700;
}

.t-popular-card__oldprice {
  display: block;
  margin: 0;
  opacity: 0.5;
  color: #343433;
  font-size: 14px;
  font-weight: 400;
  text-decoration: line-through;
}

.t-services {
  display: block;
  margin: 0 0 170px 0;
}

.t-services__header {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 40px;
  background-color: #0d0d0d;
}

.t-services__header-title {
  display: inline-block;
  margin: 20px 0;
  color: #ffffff;
  font-size: 25px;
  font-weight: 700;
}

.t-services__header-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 58px 30px 54px 32px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.16px;
  background-color: rgba(52, 52, 51, 0.8);
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.t-services__header-button img,
.t-services__header-button svg {
  margin-left: 10px;
}

.t-services__header-button:hover {
  background-color: #00b956;
  color: #ffffff;
  text-decoration: none;
}

.t-services__cards {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: stretch;
}

.t-services-card {
  display: inline-block;
  flex: 1 1 390px;
  background-position: center center;
  background-size: cover;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.t-services-card:hover {
  text-decoration: none;
}

.t-services-card:hover .t-services-card__content {
  background: rgba(0, 0, 0, 0.7) !important;
}

.t-services-card:hover .t-services-card__icon {
  display: none;
}

.t-services-card:hover .t-services-card__number {
  opacity: 0.38;
  color: #ffffff;
}

.t-services-card:hover .t-services-card__title {
  color: #ffffff;
}

.t-services-card:hover .t-services-card__text {
  display: block;
}

.t-services-card:hover .t-services-card__more {
  display: block;
}

.t-services-card:nth-child(1) {
  flex: 1 1 585px;
}

.t-services-card:nth-child(1) .t-services-card__content {
  background: #f4f4f4;
}

.t-services-card:nth-child(2) {
  flex: 1 1 585px;
}

.t-services-card:nth-child(2) .t-services-card__content {
  background: #00b956;
}

.t-services-card:nth-child(2) .t-services-card__title {
  color: #ffffff;
}

.t-services-card:nth-child(2) .t-services-card__number {
  opacity: 0.6;
  color: #ffffff;
}

.t-services-card:nth-child(3) .t-services-card__content {
  background: #343433;
}

.t-services-card:nth-child(3) .t-services-card__title {
  color: #ffffff;
}

.t-services-card:nth-child(3) .t-services-card__number {
  opacity: 0.6;
  color: #ffffff;
}

.t-services-card:nth-child(4) .t-services-card__content {
  background: #ffffff;
}

.t-services-card:nth-child(6) {
  flex: 1 1 1170px;
}

.t-services-card:nth-child(6) .t-services-card__content {
  background: #00b956;
}

.t-services-card:nth-child(6) .t-services-card__title {
  color: #ffffff;
}

.t-services-card:nth-child(6) .t-services-card__number {
  opacity: 0.6;
  color: #ffffff;
}

.t-services-card__content {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  min-height: 340px;
  padding: 35px 40px;
  background-color: #f4f4f4;
  transition: all 0.2s ease-out;
}

.t-services-card__number {
  display: block;
  width: 100%;
  margin: 0;
  opacity: 0.38;
  color: #000000;
  font-size: 16px;
  font-weight: 700;
  text-align: right;
  transition: all 0.2s ease-out;
}

.t-services-card__icon {
  display: block;
  max-width: 60px;
  max-height: 60px;
  margin-bottom: 20px;
  transition: all 0.2s ease-out;
}

.t-services-card__icon img,
.t-services-card__icon svg {
  max-width: 100%;
  max-height: 100%;
}

.t-services-card__title {
  display: block;
  margin: 0;
  color: #343433;
  font-size: 18px;
  font-weight: 600;
  transition: all 0.2s ease-out;
}

.t-services-card__text {
  display: none;
  max-height: 132px;
  overflow: hidden;
  margin: 36px 0 38px 0;
  opacity: 0.8;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.38;
  transition: all 0.2s ease-out;
}

.t-services-card__more {
  display: none;
  align-items: center;
  color: #00b956;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.28px;
  transition: all 0.2s ease-out;
}

.t-services-card__more img,
.t-services-card__more svg {
  display: inline-block;
  margin-left: 8px;
}

.t-features {
  display: block;
  margin: 0;
}

.t-features__cards {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: stretch;
}

.t-features__prep {
  display: inline-flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  padding: 20px 30px;
  width: 100%;
  max-width: 293px;
  min-height: 293px;
  margin: -1px 0 0 -1px;
  border: 1px solid #343433;
  background-color: #343433;
}

.t-features__title {
  display: block;
  margin: 0;
  color: rgba(255, 255, 255, 0.87);
  font-size: 30px;
  font-weight: 300;
}

.t-features__title b,
.t-features__title strong {
  color: #00b956;
  font-weight: 700;
}

.t-features__end {
  display: inline-flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100%;
  max-width: 293px;
  min-height: 293px;
  margin: -1px 0 0 -1px;
  border: 1px solid #00b956;
  background-color: #00b956;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.16px;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.t-features__end:hover {
  transform: scale(1.025);
  color: #ffffff;
  text-decoration: none;
}

.t-features-card {
  display: inline-flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 30px 30px 20px 30px;
  width: 100%;
  max-width: 293px;
  min-height: 293px;
  margin: -1px 0 0 -1px;
  border: 1px solid #e1e1e1;
  background-color: #ffffff;
}

.t-features-card__number {
  display: block;
  width: 100%;
  opacity: 0.38;
  color: #232323;
  font-size: 16px;
  font-weight: 700;
  text-align: right;
}

.t-features-card__title {
  display: block;
  min-height: 42px;
  margin: 0;
  color: #343433;
  font-size: 16px;
  font-weight: 500;
}

.t-features-card__icon {
  display: block;
  width: 100%;
  max-width: 60px;
  max-height: 60px;
  margin-bottom: 26px;
}

.t-features-card__icon img,
.t-features-card__icon svg {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

.t-timeline {
  display: block;
  padding: 120px 0;
  background-color: #fafafa;
}

.t-timeline__cards {
  display: block;
}

.t-timeline-card {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: stretch;
  margin-bottom: 2px;
}

.t-timeline-card:nth-child(even) .t-timeline-card__number {
  margin-left: auto;
}

.t-timeline-card:nth-child(2n+3) .t-timeline-card__wrap {
  padding-right: 15px;
}

.t-timeline-card__number {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 150px;
  min-height: 150px;
  padding: 5px;
  background-color: #00b956;
  color: #ffffff;
  font-size: 40px;
  font-weight: 700;
  text-align: center;
}

.t-timeline-card__wrap {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 510px;
  padding: 26px 45px 26px 50px;
  background-color: #343433;
}

.t-timeline-card__title {
  display: block;
  margin: 0;
  color: rgba(255, 255, 255, 0.87);
  font-size: 30px;
  font-weight: 300;
}

.t-timeline-card__title b,
.t-timeline-card__title strong {
  color: #00b956;
  font-weight: 700;
}

.t-timeline-card__content {
  max-width: 365px;
  padding: 0 5px;
}

.t-timeline-card__content p {
  display: block;
  margin: 15px 0 0 0;
  opacity: 0.8;
  color: #ffffff;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.47;
}

.t-timeline-card__subtitle {
  display: block;
  margin: 0;
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
}

.t-partners {
  display: block;
  padding: 110px 0 120px 0;
  background: #ffffff;
}

.t-partners__title {
  display: block;
  margin: 0 0 40px 0;
  color: #343433;
  font-size: 25px;
  font-weight: 700;
}

.t-partners__cards {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: stretch;
}

.t-partners-card {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 235px;
  height: 235px;
  margin: -1px 0 0 -1px;
  border: 1px solid #e1e1e1;
  background-color: #ffffff;
}

.t-partners-card img,
.t-partners-card svg {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

.t-consult {
  display: block;
  position: relative;
  width: 100vw;
  overflow: hidden;
  background: #fafafa;
}

.t-consult__title {
  display: block;
  margin: 120px 0 80px 0;
  color: #343433;
  font-size: 30px;
  font-weight: 700;
}

.t-consult__form {
  display: block;
  width: 100%;
  max-width: 470px;
}

.t-consult__input {
  display: block;
  position: relative;
  margin: 0 0 30px 0;
}

.t-consult__input label {
  display: block;
  position: absolute;
  top: 50%;
  left: 30px;
  margin: -12px 0 0 0;
  padding: 0;
  color: rgba(52, 52, 51, 0.35);
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.2px;
  pointer-events: none;
  z-index: 1;
  transition: all 0.2s ease-out;
}

.t-consult__input input {
  display: block;
  width: 100%;
  border: 1px solid #e1e1e1;
  background-color: #ffffff;
  padding: 30px;
  color: #343433;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.2px;
  outline: none;
  transition: all 0.2s ease-out;
}

.t-consult__input input:focus ~ label, .t-consult__input input:valid ~ label {
  top: 0;
  color: rgba(52, 52, 51, 0.7);
  font-size: 18px;
  text-shadow: 0px 1px 0 #ffffff, 0px -1px 0 #ffffff, 1px 0px 0 #ffffff, -1px 0px 0 #ffffff, 1px 1px 0 #ffffff, 1px -1px 0 #ffffff, -1px 1px 0 #ffffff, -1px -1px 0 #ffffff, 0px 2px 0 #ffffff, 0px -2px 0 #ffffff, 2px 0px 0 #ffffff, -2px 0px 0 #ffffff, 2px 2px 0 #ffffff, 2px -2px 0 #ffffff, -2px 2px 0 #ffffff, -2px -2px 0 #ffffff;
}

.t-consult__button {
  display: block;
  width: 100%;
  padding: 28px 12px;
  border: 0;
  outline: none;
  background-color: #00b956;
  color: #ffffff;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.2px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.t-consult__button:hover {
  background-color: #25e780;
  color: #ffffff;
  text-decoration: none;
}

.t-consult__info {
  display: flex;
  align-items: center;
  margin: 18px 0 0 0;
  color: rgba(52, 52, 51, 0.6);
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.14px;
}

.t-consult__info img,
.t-consult__info svg {
  margin-right: 15px;
}

.t-consult__image {
  display: block;
  height: 100%;
  width: auto;
}

.t-home-seo {
  display: block;
  padding: 110px 0 100px 0;
  background-color: #ffffff;
  color: rgba(52, 52, 51, 0.8);
  font-size: 16px;
  font-weight: 300;
  line-height: 1.38;
}

.t-home-seo_catalog {
  background-color: #fafafa;
}

.t-home-seo__title {
  display: block;
  margin: 0 0 45px 0;
  color: #343433;
  font-size: 25px;
  font-weight: 700;
}

.t-home-seo p {
  display: block;
  margin: 0 0 20px 0;
  color: rgba(52, 52, 51, 0.8);
  font-size: 16px;
  font-weight: 300;
  line-height: 1.38;
}

.t-home-seo h1,
.t-home-seo h2,
.t-home-seo h3,
.t-home-seo h4,
.t-home-seo h5,
.t-home-seo h6 {
  display: block;
  margin: 0 0 20px 0;
  color: #343433;
  font-weight: 700;
}

.t-home-seo h1 {
  font-weight: 300;
  font-size: 35px;
}

.t-home-seo h2 {
  font-size: 25px;
}

.t-home-seo h3 {
  font-size: 20px;
}

.t-home-seo h4 {
  font-size: 18px;
}

.t-home-seo h5 {
  font-size: 14px;
}

.t-home-seo h6 {
  font-size: 12px;
}

.t-home-seo ul {
  display: block;
  margin: 0 0 0 20px;
  padding: 0 0 20px 0;
  color: rgba(0, 0, 0, 0.8);
  font-size: 16px;
  font-weight: 300;
  line-height: 1.88;
}

.t-home-seo a {
  color: #00b956;
  cursor: pointer;
  text-decoration: underline;
  transition: all 0.2s ease-out;
}

.t-home-seo a:hover {
  color: #00b956;
  text-decoration: none;
}

.t-footer {
  display: block;
  padding: 80px 0 30px 0;
  background-color: rgba(0, 0, 0, 0.87);
}

.t-footer__title {
  display: block;
  margin: 0 0 27px 0;
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.18px;
}

.t-footer__subtitle {
  display: block;
  margin: 0 0 10px 0;
  opacity: 0.8;
  color: #ffffff;
  font-size: 16px;
  font-weight: 300;
}

.t-footer__phone {
  display: flex;
  align-items: center;
  margin: 0 0 15px 0;
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.14px;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.t-footer__phone img,
.t-footer__phone svg {
  display: inline-block;
  max-width: 22px;
  max-height: 22px;
  margin-right: 11px;
}

.t-footer__phone:hover {
  color: #ffffff;
  text-decoration: none;
}

.t-footer__adress {
  display: flex;
  align-items: center;
  margin: 0 0 15px 0;
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.14px;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.t-footer__adress img,
.t-footer__adress svg {
  display: inline-block;
  max-width: 22px;
  max-height: 22px;
  margin-right: 11px;
}

.t-footer__email {
  display: flex;
  align-items: center;
  margin: 0 0 15px 0;
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.14px;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.t-footer__email img,
.t-footer__email svg {
  display: inline-block;
  max-width: 22px;
  max-height: 22px;
  margin-right: 7px;
}

.t-footer__email span {
  text-decoration: underline;
}

.t-footer__email:hover {
  color: #ffffff;
  text-decoration: none;
}

.t-footer__email:hover span {
  text-decoration: none;
}

.t-footer__nav {
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
}

.t-footer__nav li {
  display: block;
}

.t-footer__nav li a {
  display: block;
  margin: 0;
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  font-weight: 300;
  line-height: 1.8;
  letter-spacing: 0.14px;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.t-footer__nav li a:hover {
  color: #ffffff;
  text-decoration: none;
}

.t-footer__more {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-end;
  text-align: right;
}

.t-footer__pay {
  display: block;
  padding: 0 0 35px 0;
}

.t-footer__pay img,
.t-footer__pay svg {
  max-width: 100%;
  max-height: 100%;
}

.t-footer__social {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.t-footer__social a {
  display: inline-block;
  max-width: 30px;
  max-height: 30px;
  margin: 0 0 5px 5px;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.t-footer__social a:hover {
  transform: scale(1.1);
  text-decoration: none;
}

.t-footer__bottom {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin: 90px 0 0 0;
  padding: 22px 0 0 0;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.t-footer__copyright {
  display: block;
  margin: 0;
  opacity: 0.38;
  color: #ffffff;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.14px;
}

.t-footer__policy {
  display: block;
  margin: 0;
  opacity: 0.38;
  color: #ffffff;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.14px;
  cursor: pointer;
  text-decoration: underline;
  transition: all 0.2s ease-out;
}

.t-footer__policy:hover {
  opacity: 1;
  color: #ffffff;
  text-decoration: none;
}

.t-breadcrumps {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin: 0;
  padding: 28px 0;
  list-style: none;
  color: rgba(52, 52, 51, 0.6);
  font-size: 14px;
  font-weight: 300;
}

.t-breadcrumps img,
.t-breadcrumps svg {
  display: inline-block;
  max-width: 10px;
  max-height: 10px;
  margin: 0 10px;
}

.t-breadcrumps a {
  color: #00b956;
  font-size: 14px;
  font-weight: 300;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.t-breadcrumps a:hover {
  color: #343433;
  text-decoration: none;
}

.t-stocks {
  display: block;
  padding: 35px 0 120px 0;
}

.t-stocks__title {
  display: block;
  margin: 0 0 20px 0;
  color: #343433;
  font-size: 25px;
  font-weight: 700;
}

.t-stocks__sort {
  display: block;
  position: relative;
  border: 1px solid #e1e1e1;
  background-color: #ffffff;
}

.t-stocks__sort:before {
  display: block;
  position: absolute;
  content: '';
  width: 17px;
  height: 20px;
  left: 15px;
  top: 50%;
  margin-top: -10px;
  background: url("../images/icon_sort.png") no-repeat center center;
  background-size: contain;
  pointer-events: none;
}

.t-stocks__sort:after {
  display: block;
  position: absolute;
  content: '';
  width: 12px;
  height: 8px;
  right: 15px;
  top: 50%;
  margin-top: -4px;
  background: url("../images/icon_arrow_down.png") no-repeat center center;
  background-size: contain;
  pointer-events: none;
}

.t-stocks__sort select {
  display: block;
  width: 100%;
  padding: 15px 30px 15px 50px;
  border: 0;
  outline: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  color: rgba(52, 52, 51, 0.8);
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.16px;
}

.t-stocks__cards {
  display: block;
  padding: 23px 0 0 0;
}

.t-stocks-card {
  display: block;
  width: 100%;
  max-width: 570px;
  margin: 0 auto 30px auto;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.t-stocks-card:hover {
  text-decoration: none;
}

.t-stocks-card:hover .t-stocks-card__arrow {
  background: #00b956;
}

.t-stocks-card:hover .t-stocks-card__arrow img,
.t-stocks-card:hover .t-stocks-card__arrow svg {
  filter: brightness(0.3);
}

.t-stocks-card__image {
  display: block;
  width: 100%;
  height: 300px;
  background-position: center center;
  background-size: cover;
}

.t-stocks-card__content {
  display: block;
  width: 100%;
  padding: 20px 25px 25px 15px;
  background-color: #343433;
}

.t-stocks-card__date {
  display: block;
  margin: 0 0 15px 0;
  color: rgba(255, 255, 255, 0.7);
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0.13px;
}

.t-stocks-card__date_hot {
  position: relative;
  padding-left: 20px;
  color: #fed430;
}

.t-stocks-card__date_hot:before {
  display: block;
  position: absolute;
  content: '';
  width: 16px;
  height: 20px;
  left: 0;
  margin-top: -2px;
  background: url("../images/icon_hot.png") no-repeat center center;
  background-size: contain;
}

.t-stocks-card__title {
  display: block;
  margin: 0;
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
}

.t-stocks-card__arrow {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border: 1px solid #00b956;
  border-radius: 999rem;
  transition: all 0.2s ease-out;
}

.t-stocks-card__arrow img,
.t-stocks-card__arrow svg {
  display: block;
  max-width: 30px;
  max-height: 30px;
  transition: all 0.2s ease-out;
}

.t-pagination {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  margin: 0;
  padding: 70px 0 0 0;
  list-style: none;
}

.t-pagination_active a {
  background: #00b956;
  color: #fefefe !important;
}

.t-pagination li {
  display: inline-block;
}

.t-pagination li a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  height: 40px;
  padding: 2px;
  color: rgba(52, 52, 51, 0.7);
  font-size: 18px;
  font-weight: 300;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.t-pagination li a:hover {
  background: #ffffff;
  color: #00b956 !important;
  text-decoration: none;
}

.t-pagination li b,
.t-pagination li strong {
  margin: 0 15px;
  color: #343433;
  font-size: 18px;
  font-weight: 400;
}

.t-basket {
  display: block;
}

.t-basket_show_mob {
  display: none;
}

.t-basket__title {
  display: block;
  margin: 0 0 50px 0;
  color: #343433;
  font-size: 25px;
  font-weight: 700;
}

.t-basket__subtitles {
  display: block;
  margin: 0 0 30px 0;
  padding: 8px 10px;
  border-bottom: 1px solid #d9d9d9;
  color: rgba(52, 52, 51, 0.7);
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.7px;
}

.t-basket__cards {
  display: block;
}

.t-basket-card {
  display: block;
  position: relative;
  width: 100%;
  margin: 0 0 5px 0;
  padding: 18px 20px;
  background-color: #fafafa;
}

.t-basket-card a {
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.t-basket-card a:hover {
  color: #00b956;
  text-decoration: none;
}

.t-basket-card a:hover h1,
.t-basket-card a:hover h2,
.t-basket-card a:hover h3,
.t-basket-card a:hover h4 {
  color: #00b956;
}

.t-basket-card__title {
  display: block;
  margin: 0 0 8px 0;
  color: #343434;
  font-size: 18px;
  font-weight: 400;
  transition: all 0.2s ease-out;
}

.t-basket-card__category {
  display: block;
  margin: 0 0 20px 0;
  color: rgba(0, 0, 0, 0.3);
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0.13px;
}

.t-basket-card__image {
  display: block;
  max-width: 160px;
  height: 55px;
}

.t-basket-card__image img,
.t-basket-card__image svg {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

.t-basket-card input[type=number]::-webkit-inner-spin-button,
.t-basket-card input[type=number]::-webkit-outer-spin-button {
  /* transform: scale(1.5); */
  height: 46px;
  opacity: 1;
}

.t-basket-card__counter {
  display: inline-block;
  padding: 16px 2px 16px 14px;
  border: 1px solid #e3e3e3;
  outline: none;
  background-color: #f5f5f5;
  color: rgba(52, 52, 52, 0.8);
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.35px;
}

.t-basket-card__price {
  display: block;
  margin: 8px 0;
  color: #343434;
  font-size: 20px;
  font-weight: 700;
}

.t-basket-card__delete {
  display: block;
  width: 14px;
  height: 14px;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.t-basket-card__delete img,
.t-basket-card__delete svg {
  max-width: 100%;
  max-height: 100%;
}

.t-basket-card__delete:hover {
  transform: scale(1.1);
  text-decoration: none;
}

.t-basket__summary {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  align-items: center;
  padding: 5px 0 110px 0;
}

.t-basket__button {
  display: inline-block;
  padding: 20px 72px;
  background-color: #00b956;
  border: 0;
  outline: none;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.32px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.t-basket__button:hover {
  background-color: #25e780;
  color: #ffffff;
  text-decoration: none;
}

.t-basket__summ {
  display: inline-block;
  margin: 10px 30px;
  color: rgba(52, 52, 51, 0.8);
  font-size: 16px;
  font-weight: 300;
  line-height: 1.38;
}

.t-basket__summ b,
.t-basket__summ strong {
  display: inline-block;
  color: #343433;
  font-size: 20px;
  font-weight: 700;
}

.t-order {
  display: block;
  padding: 120px 0 90px 0;
  background-color: #fafafa;
}

.t-order__title {
  display: block;
  margin: 0 0 16px 0;
  color: #343433;
  font-size: 25px;
  font-weight: 700;
}

.t-order__text {
  display: block;
  margin: 0 0 50px 0;
  color: rgba(56, 56, 56, 0.6);
  font-size: 14px;
  font-weight: 300;
  line-height: 1.43;
  letter-spacing: 0.14px;
}

.t-order__subtitle {
  display: block;
  margin: 0 0 10px 0;
  color: #343433;
  font-size: 18px;
  font-weight: 400;
}

.t-order__wrap {
  display: block;
  padding: 20px 0 30px 0;
  border-top: 1px solid #d4d4d4;
}

.t-order-choose {
  display: block;
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: -10px 0 0 0;
  padding: 0;
}

.t-order-choose input {
  width: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
}

.t-order-choose input:checked ~ .t-order-choose__this {
  background: url("../images/icon_checkbox_checked.png") no-repeat 0 0;
}

.t-order-choose__this {
  display: block;
  padding: 0 0 0 35px;
  background: url("../images/icon_checkbox.png") no-repeat 0 0;
  color: rgba(52, 52, 51, 0.8);
  font-size: 16px;
  font-weight: 300;
  line-height: 1.38;
  letter-spacing: 0.16px;
}

.t-order-choose__this a {
  color: #00b956;
  cursor: pointer;
  text-decoration: underline;
  transition: all 0.2s ease-out;
}

.t-order-choose__this a:hover {
  color: #00b956;
  text-decoration: none;
}

.t-order__drop {
  display: block;
  position: relative;
  margin: 0 0 10px 0;
  border: 1px solid #e0e0e0;
  background-color: #ffffff;
}

.t-order__drop:after {
  display: block;
  position: absolute;
  content: '';
  width: 12px;
  height: 8px;
  right: 20px;
  top: 50%;
  margin-top: -4px;
  background: url("../images/icon_arrow_down.png") no-repeat center center;
  background-size: contain;
  pointer-events: none;
}

.t-order__drop select {
  display: block;
  width: 100%;
  padding: 21px 30px 21px 24px;
  border: 0;
  outline: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  color: rgba(52, 52, 51, 0.9);
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.16px;
}

.t-order__input {
  display: block;
  position: relative;
  margin: 0 0 10px 0;
}

.t-order__input_p_l_5 {
  padding-left: 5px;
}

.t-order__input_p_r_5 {
  padding-right: 5px;
}

.t-order__input label {
  display: block;
  position: absolute;
  top: 50%;
  left: 24px;
  margin: -9px 0 0 0;
  padding: 0;
  color: rgba(52, 52, 51, 0.35);
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.16px;
  pointer-events: none;
  z-index: 1;
  transition: all 0.2s ease-out;
}

.t-order__input input {
  display: block;
  width: 100%;
  border: 1px solid #e1e1e1;
  background-color: #ffffff;
  padding: 20px 24px;
  color: rgba(52, 52, 51, 0.8);
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.16px;
  outline: none;
  transition: all 0.2s ease-out;
}

.t-order__input input:focus ~ label, .t-order__input input:valid ~ label {
  top: 0;
  color: rgba(52, 52, 51, 0.7);
  font-size: 14px;
  text-shadow: 0px 1px 0 #ffffff, 0px -1px 0 #ffffff, 1px 0px 0 #ffffff, -1px 0px 0 #ffffff, 1px 1px 0 #ffffff, 1px -1px 0 #ffffff, -1px 1px 0 #ffffff, -1px -1px 0 #ffffff, 0px 2px 0 #ffffff, 0px -2px 0 #ffffff, 2px 0px 0 #ffffff, -2px 0px 0 #ffffff, 2px 2px 0 #ffffff, 2px -2px 0 #ffffff, -2px 2px 0 #ffffff, -2px -2px 0 #ffffff;
}

.t-order__button {
  display: block;
  width: 100%;
  padding: 20px 12px;
  border: 0;
  outline: none;
  background-color: #00b956;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.32px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.t-order__button:hover {
  background-color: #25e780;
  color: #ffffff;
  text-decoration: none;
}

.t-order__please {
  display: block;
  margin: 0;
  padding: 10px 0 20px 0;
  opacity: 0.8;
  color: #343433;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.38;
  letter-spacing: 0.16px;
}

.t-order__info {
  display: flex;
  align-items: center;
  margin: 16px 0 0 0;
  color: rgba(52, 52, 51, 0.6);
  color: #343433;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.14px;
}

.t-order__info img,
.t-order__info svg {
  margin-right: 12px;
}

.t-filter {
  display: block;
  width: 100%;
  max-width: 270px;
  margin: 10px auto 20px auto;
  padding: 30px 20px 20px 20px;
  background-color: #343433;
}

.t-filter__title {
  display: block;
  position: relative;
  margin: 0 0 12px 0;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
}

.t-filter__title_collapse {
  padding-right: 20px;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.t-filter__title_collapse:after {
  display: block;
  position: absolute;
  content: '';
  top: 5px;
  right: 0;
  width: 12px;
  height: 8px;
  background: url("../images/icon_collapse.png") no-repeat right top;
  background-size: contain;
  z-index: 1;
}

.t-filter__title_collapse_active:after {
  transform: rotate(180deg);
}

.t-filter__title_collapse:hover {
  color: #00b956;
  text-decoration: none;
}

.t-filter__content {
  display: none;
  padding: 8px 0 30px 0;
}

.t-filter__group {
  display: block;
}

.t-filter__price {
  display: block;
  margin: 0;
  padding: 0 0 20px 0;
  color: #ffffff;
  font-size: 16px;
  font-weight: 300;
}

.t-filter #price {
  margin-bottom: 36px;
}

.t-filter .noUi-target {
  height: 3px;
  border: 0;
  outline: none;
  background: rgba(255, 255, 255, 0.38);
  border-radius: 0;
  box-shadow: none;
}

.t-filter .noUi-connect {
  background: #00b956;
}

.t-filter .noUi-horizontal .noUi-handle {
  width: 17px;
  height: 17px;
  top: -8px;
  border: 0;
  outline: none;
  border-radius: 999rem;
  background-color: #ffffff;
}

.t-filter .noUi-horizontal .noUi-handle:before, .t-filter .noUi-horizontal .noUi-handle:after {
  display: none;
}

.t-filter-check {
  display: block;
  position: relative;
  width: 100%;
  margin: -10px 0 5px 0;
  padding: 0;
}

.t-filter-check input {
  width: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
}

.t-filter-check input:checked ~ .t-filter-check__this {
  background: url("../images/filter_checkbox_checked.png") no-repeat 0 0;
}

.t-filter-check__this {
  display: flex;
  justify-content: space-between;
  padding: 0 0 0 30px;
  background: url("../images/filter_checkbox.png") no-repeat 0 0;
  color: #ffffff;
  font-size: 16px;
  font-weight: 300;
}

.t-filter-check__this a {
  color: #00b956;
  cursor: pointer;
  text-decoration: underline;
  transition: all 0.2s ease-out;
}

.t-filter-check__this a:hover {
  color: #00b956;
  text-decoration: none;
}

.t-filter-check__counter {
  opacity: 0.38;
  color: #ffffff;
  font-size: 16px;
  font-weight: 300;
}

.t-filter__button {
  display: block;
  width: 100%;
  margin: 20px 0 0 0;
  padding: 16px 12px;
  border: 1px solid #00b956;
  background: transparent;
  outline: none;
  color: rgba(0, 185, 86, 0.9);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.32px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.t-filter__button:hover {
  background: #00b956;
  color: #ffffff;
  text-decoration: none;
}

.t-catalog {
  display: block;
  padding: 10px 0 100px 0;
}

.t-catalog__title {
  display: block;
  margin: 0 0 16px 0;
  color: #343433;
  font-size: 25px;
  font-weight: 700;
}

.t-catalog__sort {
  display: block;
  position: relative;
  border: 1px solid #e1e1e1;
  background-color: #ffffff;
}

.t-catalog__sort:before {
  display: block;
  position: absolute;
  content: '';
  width: 17px;
  height: 20px;
  left: 15px;
  top: 50%;
  margin-top: -10px;
  background: url("../images/icon_sort.png") no-repeat center center;
  background-size: contain;
  pointer-events: none;
}

.t-catalog__sort:after {
  display: block;
  position: absolute;
  content: '';
  width: 12px;
  height: 8px;
  right: 15px;
  top: 50%;
  margin-top: -4px;
  background: url("../images/icon_arrow_down.png") no-repeat center center;
  background-size: contain;
  pointer-events: none;
}

.t-catalog__sort select {
  display: block;
  width: 100%;
  padding: 15px 30px 15px 50px;
  border: 0;
  outline: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  color: rgba(52, 52, 51, 0.8);
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.16px;
}

.t-catalog__cards {
  display: block;
  margin-top: 16px;
}

.t-catalog-card {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 1 270px;
  position: relative;
  min-height: 263px;
  margin: 0 auto 30px auto;
  padding: 22px 20px;
  background-color: #f4f4f4;
  overflow: hidden;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.t-catalog-card:after {
  display: block;
  position: absolute;
  content: attr(data-special);
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(180deg);
  top: 0;
  right: -4px;
  height: 100%;
  opacity: 0.2;
  text-shadow: -1px 0 #343433, 0 1px #343433, 1px 0 #343433, 0 -1px #343433;
  color: #f4f4f4;
  font-size: 30px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  line-height: 1;
  text-align: center;
  z-index: 1;
}

.t-catalog-card:hover {
  background: #343433;
  text-decoration: none;
}

.t-catalog-card:hover .t-catalog-card__title {
  color: #ffffff;
}

.t-catalog-card:hover .t-catalog-card__category {
  color: #ffffff;
}

.t-catalog-card:hover .t-catalog-card__price {
  color: #ffffff;
}

.t-catalog-card:hover .t-catalog-card__oldprice {
  color: #ffffff;
}

.t-catalog-card__title {
  display: block;
  max-height: 38px;
  overflow: hidden;
  margin: 0;
  color: #343433;
  font-size: 16px;
  font-weight: 400;
}

.t-catalog-card__category {
  display: block;
  max-height: 16px;
  overflow: hidden;
  margin: 6px 0 0 0;
  opacity: 0.5;
  color: #343433;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0.13px;
}

.t-catalog-card__image {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 100%;
  width: 100%;
  height: 120px;
  padding: 5px;
}

.t-catalog-card__image img,
.t-catalog-card__image svg {
  max-width: 100%;
  max-height: 100%;
}

.t-catalog-card__price {
  display: block;
  margin: 0;
  color: #343433;
  font-size: 20px;
  font-weight: 700;
}

.t-catalog-card__oldprice {
  display: block;
  margin: 0;
  opacity: 0.5;
  color: #343433;
  font-size: 14px;
  font-weight: 400;
  text-decoration: line-through;
}

.t-catalog .t-pagination {
  padding-top: 20px;
}

.t-item {
  display: block;
  padding: 20px 0 90px 0;
}

.t-item__images {
  display: block;
  position: relative;
  width: 100%;
  border: 1px solid #e1e1e1;
  background-color: #ffffff;
}

.t-item__content {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  width: 100%;
  min-height: 450px;
  overflow: hidden;
  padding: 45px 30px 30px 30px;
  background-color: #343433;
}

.t-item__content > div {
  width: 100%;
}

.t-item__content:after {
  display: block;
  position: absolute;
  content: attr(data-special);
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(180deg);
  top: 0;
  right: -5px;
  height: 100%;
  opacity: 0.12;
  text-shadow: -1px 0 #ffffff, 0 1px #ffffff, 1px 0 #ffffff, 0 -1px #ffffff;
  color: #343433;
  font-size: 45px;
  font-weight: 400;
  letter-spacing: 0.45px;
  text-transform: uppercase;
  line-height: 1;
  text-align: center;
  z-index: 1;
}

.t-item__title {
  display: block;
  margin: 0;
  color: #ffffff;
  font-size: 25px;
  font-weight: 700;
}

.t-item__category {
  display: block;
  margin: 10px 0;
  opacity: 0.4;
  color: #ffffff;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0.13px;
}

.t-item__price {
  display: block;
  margin: 7px 0;
  color: #ffffff;
  font-size: 25px;
  font-weight: 700;
}

.t-item__oldprice {
  display: block;
  margin: 0;
  opacity: 0.5;
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
  text-decoration: line-through;
}

.t-item input[type=number]::-webkit-inner-spin-button,
.t-item input[type=number]::-webkit-outer-spin-button {
  height: 46px;
  /* transform: scale(1.5); */
  opacity: 0.4;
}

.t-item__counter {
  display: inline-block;
  padding: 16px 2px 16px 14px;
  border: 1px solid #686868;
  outline: none;
  background-color: #424242;
  color: rgba(255, 255, 255, 0.24);
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.35px;
}

.t-item__button {
  display: inline-block;
  padding: 16px 48px;
  background-color: #00b956;
  border: 0;
  outline: none;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.32px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.t-item__button:hover {
  background-color: #25e780;
  color: #ffffff;
  text-decoration: none;
}

.t-item__consult {
  display: inline-block;
  padding: 16px 29px;
  background-color: transparent;
  border: 1px solid #fed430;
  outline: none;
  color: #fed430;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.32px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.t-item__consult:hover {
  background-color: #d3ad15;
  color: #ffffff;
  text-decoration: none;
}

.t-item__info {
  display: flex;
  align-items: center;
  margin: 8px 0;
}

.t-item__info-title {
  margin-right: 6px;
  color: #9e9e9e;
  font-size: 14px;
  font-weight: 500;
}

.t-item__info-text {
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
}

.t-item__icon {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

.t-item__icon img,
.t-item__icon svg {
  max-width: 100%;
  max-height: 100%;
}

.t-item__row {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  max-width: 490px;
}

.t-item__big {
  display: block;
}

.t-item__big-card {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 355px;
  padding: 20px;
  background: #ffffff;
  transition: all 0.2s ease-out;
}

.t-item__big-card img,
.t-item__big-card svg {
  max-width: 100%;
  max-height: 100%;
}

.t-item__small {
  display: block;
}

.t-item__small-card {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100px;
  height: 100px;
  padding: 10px;
  background: #f4f4f4;
  transition: all 0.2s ease-out;
}

.t-item__small-card img,
.t-item__small-card svg {
  max-width: 100%;
  max-height: 100%;
}

.t-item__small .swiper-slide {
  max-width: 100px;
}

.t-item .swiper-slide-thumb-active .t-item__small-card {
  background: #ffffff;
}

.t-item__brand {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  max-width: 100px;
  height: 100px;
  pointer-events: none;
  z-index: 3;
}

.t-item__brand img,
.t-item__brand svg {
  max-width: 100%;
  max-height: 100%;
}

.t-tabs {
  display: block;
  position: relative;
  width: 100%;
}

.t-tabs__header {
  display: flex;
  flex-flow: row wrap;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
}

.t-tabs__header:after {
  display: block;
  position: absolute;
  content: '';
  width: 100%;
  left: 0;
  height: 1px;
  bottom: 0px;
  background: #d8dce5;
}

.t-tabs__header li {
  display: inline-block;
  padding: 12px 20px;
  border-bottom: 2px solid transparent;
  color: rgba(52, 52, 51, 0.7);
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.7px;
  z-index: 1;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.t-tabs__header li.current {
  border-color: #00b956;
  color: #00b956;
}

.t-tabs__header li:hover {
  color: #00b956;
  text-decoration: none;
}

.t-tabs__content {
  display: none;
  position: relative;
  width: 100%;
  max-width: 770px;
  min-height: 400px;
  padding: 50px 0;
  color: rgba(41, 41, 41, 0.8);
  font-size: 16px;
  font-weight: 300;
  line-height: 1.38;
  letter-spacing: 0.16px;
}

.t-tabs__content p {
  margin: 0 0 20px 0;
}

.t-tabs__content.current {
  display: block;
}

.t-tabs__content h1,
.t-tabs__content h2,
.t-tabs__content h3,
.t-tabs__content h4,
.t-tabs__content h5,
.t-tabs__content h6 {
  display: block;
  margin: 0 0 20px 0;
  color: #343433;
  font-weight: 700;
}

.t-tabs__content h1 {
  font-weight: 300;
  font-size: 35px;
}

.t-tabs__content h2 {
  font-size: 25px;
}

.t-tabs__content h3 {
  font-size: 20px;
}

.t-tabs__content h4 {
  font-size: 18px;
}

.t-tabs__content h5 {
  font-size: 14px;
}

.t-tabs__content h6 {
  font-size: 12px;
}

.t-tabs__content ul {
  display: block;
  margin: 0 0 0 20px;
  padding: 0 0 20px 0;
  color: rgba(0, 0, 0, 0.8);
  font-size: 16px;
  font-weight: 300;
  line-height: 1.88;
}

.t-tabs__content a {
  color: #00b956;
  cursor: pointer;
  text-decoration: underline;
  transition: all 0.2s ease-out;
}

.t-tabs__content a:hover {
  color: #00b956;
  text-decoration: none;
}

.t-similar {
  display: block;
  padding: 80px 0 90px 0;
  background: #ffffff;
}

.t-similar__title {
  display: block;
  margin: 0 0 36px 0;
  color: #343433;
  font-size: 25px;
  font-weight: 700;
}

.t-fast-form {
  display: block;
  position: relative;
  width: 100%;
  max-width: 470px;
  padding: 35px 30px 30px 30px;
  background-color: #f4f4f4;
}

.t-fast-form__title {
  display: block;
  margin: 0 0 26px 0;
  color: #343433;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
}

.t-fast-form__input {
  display: block;
  position: relative;
  margin: 0 0 10px 0;
}

.t-fast-form__input label {
  display: block;
  position: absolute;
  top: 50%;
  left: 24px;
  margin: -9px 0 0 0;
  padding: 0;
  color: rgba(52, 52, 51, 0.35);
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.16px;
  pointer-events: none;
  z-index: 1;
  transition: all 0.2s ease-out;
}

.t-fast-form__input input {
  display: block;
  width: 100%;
  border: 1px solid #e1e1e1;
  background-color: #ffffff;
  padding: 20px 24px;
  color: rgba(52, 52, 51, 0.8);
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.16px;
  outline: none;
  transition: all 0.2s ease-out;
}

.t-fast-form__input input:focus ~ label, .t-fast-form__input input:valid ~ label {
  top: 0;
  color: rgba(52, 52, 51, 0.7);
  font-size: 14px;
  text-shadow: 0px 1px 0 #ffffff, 0px -1px 0 #ffffff, 1px 0px 0 #ffffff, -1px 0px 0 #ffffff, 1px 1px 0 #ffffff, 1px -1px 0 #ffffff, -1px 1px 0 #ffffff, -1px -1px 0 #ffffff, 0px 2px 0 #ffffff, 0px -2px 0 #ffffff, 2px 0px 0 #ffffff, -2px 0px 0 #ffffff, 2px 2px 0 #ffffff, 2px -2px 0 #ffffff, -2px 2px 0 #ffffff, -2px -2px 0 #ffffff;
}

.t-fast-form__button {
  display: block;
  width: 100%;
  padding: 20px 12px;
  border: 0;
  outline: none;
  background-color: #00b956;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.32px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.t-fast-form__button:hover {
  background-color: #25e780;
  color: #ffffff;
  text-decoration: none;
}

.t-fast-form__info {
  display: flex;
  align-items: center;
  margin: 18px 0 0 0;
  color: rgba(52, 52, 51, 0.6);
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.14px;
}

.t-fast-form__info img,
.t-fast-form__info svg {
  margin-right: 15px;
}

.t-article {
  display: block;
  padding: 20px 0 105px 0;
}

.t-article__welcome {
  display: block;
  width: 100%;
  background-position: center center;
  background-size: cover;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.t-article__welcome:hover {
  text-decoration: none;
}

.t-article__welcome:hover .t-article__welcome-content {
  background: rgba(0, 0, 0, 0.8);
}

.t-article__welcome-content {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-width: 585px;
  min-height: 400px;
  padding: 30px 50px 30px 50px;
  background: rgba(0, 0, 0, 0.6);
  transition: all 0.2s ease-out;
}

.t-article__welcome-date {
  display: block;
  margin: 0 0 20px 0;
  opacity: 0.7;
  color: #ffffff;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0.13px;
}

.t-article__welcome-title {
  display: block;
  margin: 0;
  color: #ffffff;
  font-size: 25px;
  font-weight: 700;
}

.t-article__welcome-link {
  display: inline-flex;
  align-items: center;
  margin: 45px 0 0 0;
  color: #00d965;
  font-size: 16px;
  font-weight: 400;
}

.t-article__welcome-link img,
.t-article__welcome-link svg {
  max-width: 15px;
  max-height: 15px;
  margin-left: 10px;
}

.t-article__wrap {
  display: block;
  padding: 70px 0 0 0;
}

.t-article__main {
  display: block;
}

.t-article__main h1,
.t-article__main h2,
.t-article__main h3,
.t-article__main h4,
.t-article__main h5,
.t-article__main h6 {
  display: block;
  margin: 0 0 20px 0;
  color: #343433;
  font-weight: 700;
}

.t-article__main h1 {
  font-weight: 300;
  font-size: 35px;
}

.t-article__main h2 {
  font-size: 25px;
}

.t-article__main h3 {
  font-size: 20px;
}

.t-article__main h4 {
  font-size: 18px;
}

.t-article__main h5 {
  font-size: 14px;
}

.t-article__main h6 {
  font-size: 12px;
}

.t-article__main ul {
  display: block;
  margin: 0 0 0 20px;
  padding: 0 0 20px 0;
  color: rgba(0, 0, 0, 0.8);
  font-size: 16px;
  font-weight: 300;
  line-height: 1.88;
}

.t-article__main p {
  display: block;
  margin: 0 0 25px;
  color: rgba(52, 52, 52, 0.8);
  font-size: 16px;
  font-weight: 300;
  line-height: 1.38;
}

.t-article__main a {
  color: #00b956;
  cursor: pointer;
  text-decoration: underline;
  transition: all 0.2s ease-out;
}

.t-article__main a:hover {
  color: #00b956;
  text-decoration: none;
}

.t-article__content {
  display: block;
  position: relative;
  width: 100%;
  padding: 110px 0 90px 0;
  background-color: #fafafa;
  color: rgba(52, 52, 52, 0.8);
  font-size: 16px;
  font-weight: 300;
}

.t-article__content h1,
.t-article__content h2,
.t-article__content h3,
.t-article__content h4,
.t-article__content h5,
.t-article__content h6 {
  display: block;
  margin: 0 0 20px 0;
  color: #343433;
  font-weight: 700;
}

.t-article__content h1 {
  font-weight: 300;
  font-size: 35px;
}

.t-article__content h2 {
  font-size: 25px;
}

.t-article__content h3 {
  font-size: 20px;
}

.t-article__content h4 {
  font-size: 18px;
}

.t-article__content h5 {
  font-size: 14px;
}

.t-article__content h6 {
  font-size: 12px;
}

.t-article__content ul {
  display: block;
  margin: 0 0 0 20px;
  padding: 0 0 20px 0;
  color: rgba(0, 0, 0, 0.8);
  font-size: 16px;
  font-weight: 300;
  line-height: 1.88;
}

.t-article__content p {
  display: block;
  margin: 0 0 25px;
  color: rgba(52, 52, 52, 0.8);
  font-size: 16px;
  font-weight: 300;
  line-height: 1.38;
}

.t-article__content a {
  color: #00b956;
  cursor: pointer;
  text-decoration: underline;
  transition: all 0.2s ease-out;
}

.t-article__content a:hover {
  color: #00b956;
  text-decoration: none;
}

.t-article__content .table-hover tbody tr:hover {
  background-color: rgba(0, 185, 86, 0.1);
  color: #343433;
}

.t-article__content .table td,
.t-article__content .table th {
  padding: 15px 20px;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  color: rgba(52, 52, 51, 0.8);
  font-size: 16px;
  font-weight: 300;
}

.t-article__content .table thead th {
  padding: 22px 20px;
  border: 0;
  background-color: #f4f4f4;
  color: rgba(52, 52, 51, 0.9);
  font-size: 16px;
  font-weight: 700;
}

.t-article__content figure {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 370px;
  height: 250px;
  margin: 0 0 30px 0;
  background: #f4f4f4;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.t-article__content figure:hover {
  text-decoration: none;
}

.t-article__content figure:hover img,
.t-article__content figure:hover svg {
  filter: brightness(0.5);
}

.t-article__content figure a {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
}

.t-article__content figure img,
.t-article__content figure svg {
  max-width: 100%;
  max-height: 100%;
  transition: all 0.2s ease-out;
}

.t-article__content figure figcaption {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 16px 18px;
  background: rgba(0, 0, 0, 0.8);
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  transition: all 0.2s ease-out;
}

.t-about {
  display: block;
  padding: 20px 0;
}

.t-about__image {
  display: block;
  width: 100%;
  height: 500px;
  background-position: center center;
  background-size: cover;
}

.t-about__wrap {
  display: block;
  position: relative;
  width: 100%;
  margin: -200px 0 0 0;
  padding: 60px 50px 20px 50px;
  background: #ffffff;
  z-index: 1;
}

.t-about__title {
  display: block;
  margin: 0 0 36px 0;
  color: #343433;
  font-size: 25px;
  font-weight: 700;
}

.t-about__prep {
  display: block;
  max-width: 740px;
  margin: 0 0 85px 0;
  color: #343433;
  font-size: 26px;
  font-weight: 300;
  line-height: 1.54;
}

.t-about__blockquote {
  display: flex;
  width: 100%;
  margin: 60px 0 20px 0;
  padding: 0 0 0 20px;
  border-left: 1px solid #00b956;
}

.t-about__blockquote-left {
  display: inline-block;
  max-width: 270px;
  padding-right: 60px;
}

.t-about__blockquote-image {
  display: block;
  width: 200px;
  height: 200px;
  border-radius: 999rem;
  background-position: center center;
  background-size: cover;
}

.t-about__blockquote-name {
  display: block;
  margin: 17px 0 8px 0;
  color: #343433;
  font-size: 18px;
  font-weight: 400;
}

.t-about__blockquote-who {
  display: block;
  margin: 0;
  opacity: 0.6;
  color: #343433;
  font-size: 14px;
  font-weight: 300;
}

.t-about__blockquote-text {
  display: inline-block;
  max-width: 785px;
  margin: 0;
  color: #343433;
  font-size: 26px;
  font-weight: 500;
  line-height: 1.54;
}

.t-about__content {
  display: block;
  position: relative;
  width: 100%;
  max-width: 680px;
  color: rgba(52, 52, 52, 0.8);
  font-size: 16px;
  font-weight: 300;
}

.t-about__content h1,
.t-about__content h2,
.t-about__content h3,
.t-about__content h4,
.t-about__content h5,
.t-about__content h6 {
  display: block;
  margin: 0 0 20px 0;
  color: #343433;
  font-weight: 700;
}

.t-about__content h1 {
  font-weight: 300;
  font-size: 35px;
}

.t-about__content h2 {
  font-size: 25px;
}

.t-about__content h3 {
  font-size: 20px;
}

.t-about__content h4 {
  font-size: 18px;
}

.t-about__content h5 {
  font-size: 14px;
}

.t-about__content h6 {
  font-size: 12px;
}

.t-about__content ul {
  display: block;
  margin: 0 0 0 20px;
  padding: 0 0 20px 0;
  color: rgba(0, 0, 0, 0.8);
  font-size: 16px;
  font-weight: 300;
  line-height: 1.88;
}

.t-about__content p {
  display: block;
  margin: 0 0 25px;
  color: rgba(52, 52, 52, 0.8);
  font-size: 16px;
  font-weight: 300;
  line-height: 1.38;
}

.t-about__content a {
  color: #00b956;
  cursor: pointer;
  text-decoration: underline;
  transition: all 0.2s ease-out;
}

.t-about__content a:hover {
  color: #00b956;
  text-decoration: none;
}

.t-about__content .table-hover tbody tr:hover {
  background-color: rgba(0, 185, 86, 0.1);
  color: #343433;
}

.t-about__content .table td,
.t-about__content .table th {
  padding: 15px 20px;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  color: rgba(52, 52, 51, 0.8);
  font-size: 16px;
  font-weight: 300;
}

.t-about__content .table thead th {
  padding: 22px 20px;
  border: 0;
  background-color: #f4f4f4;
  color: rgba(52, 52, 51, 0.9);
  font-size: 16px;
  font-weight: 700;
}

.t-about__content figure {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 370px;
  height: 250px;
  margin: 0 0 30px 0;
  background: #f4f4f4;
}

.t-about__content figure img,
.t-about__content figure svg {
  max-width: 100%;
  max-height: 100%;
}

.t-about__content figure figcaption {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 16px 18px;
  background: rgba(0, 0, 0, 0.8);
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
}

.t-gallery {
  display: block;
  padding: 120px 0 10px 0;
  position: relative;
}

.t-gallery__title {
  display: block;
  margin: 0 0 150px 0;
  color: #343433;
  font-size: 25px;
  font-weight: 700;
}

.t-gallery__cards {
  display: block;
  position: relative;
  width: 100%;
  height: 525px;
  overflow: visible;
}

.t-gallery__card {
  width: 100%;
  max-width: 500px;
  height: 365px;
  background-size: cover;
  background-position: center center;
  opacity: 0.3;
  transition: all 0.2s ease-out;
}

.t-gallery .swiper-slide-active {
  max-width: 719px;
  height: 525px;
  margin-top: -110px;
  opacity: 1;
}

.t-gallery .swiper-button-next,
.t-gallery .swiper-button-prev {
  top: -110px;
  width: 60px;
  height: 60px;
  margin: 0;
  outline: none;
  background: transparent;
  cursor: pointer;
  text-decoration: underline;
  transition: all 0.2s ease-out;
}

.t-gallery .swiper-button-next:after,
.t-gallery .swiper-button-prev:after {
  display: none;
}

.t-gallery .swiper-button-next:hover,
.t-gallery .swiper-button-prev:hover {
  transform: scale(0.9);
  text-decoration: none;
}

.t-gallery .swiper-button-next {
  left: auto;
  right: 0;
  background: url("../images/swiper_next.png") no-repeat center center;
  background-size: contain;
}

.t-gallery .swiper-button-prev {
  left: auto;
  right: 70px;
  background: url("../images/swiper_prev.png") no-repeat center center;
  background-size: contain;
}

.t-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  z-index: 100;
}

.t-modal__centered {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.8);
}

.t-modal__close {
  display: block;
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 10px;
  z-index: 1;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.t-modal__close:hover, .t-modal__close:active, .t-modal__close:focus {
  opacity: 0.7;
  text-decoration: none;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

@media (max-width: 1199px) {
  .t-consult__image {
    max-width: 100%;
    height: auto;
    max-height: 100%;
  }
  .t-header__mob {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
    padding: 12px 0;
  }
  .t-header__toggle {
    display: inline-block;
    padding: 8px 0 8px 8px;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
  }
  .t-header__toggle img,
  .t-header__toggle svg {
    max-width: 42px;
    max-height: 42px;
  }
  .t-header__toggle:hover {
    transform: scale(1.1);
    text-decoration: none;
  }
  .t-top__item img,
  .t-top__item svg {
    margin: 4px 8px 4px 0;
  }
  .t-top {
    padding: 2px 0;
  }
  .t-header__nav {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: flex-start;
    width: 100%;
  }
  .t-header__main {
    justify-content: space-around;
  }
  .t-header__subnav {
    display: block;
    width: 100%;
    padding: 6px 0;
    text-align: center;
  }
  .t-header__subnav li {
    display: block;
    width: 100%;
  }
  .t-header__subnav li a {
    display: block;
    width: 100%;
    padding: 12px;
  }
  .t-header__right {
    justify-content: space-around;
  }
  .t-header__more:before {
    display: none;
  }
  .t-header__more:after {
    display: none;
  }
  .t-top {
    display: none;
  }
  .t-header__main {
    display: none;
  }
  .t-header__right {
    display: none;
  }
  .t-home-carousel-card__content {
    margin-bottom: 20px;
    padding: 30px 20px;
  }
  .t-home-carousel-card__title {
    margin: 0 0 20px 0;
    font-size: 22px;
  }
  .t-home-carousel-card__content {
    max-height: 470px;
    overflow-y: auto;
  }
  .t-home-carousel .swiper-pagination {
    left: auto;
    right: 0;
    background: rgba(0, 0, 0, 0.4);
  }
  .t-home-wrap {
    padding: 30px 0;
  }
  .t-popular__header-button {
    padding: 12px 8px;
  }
  .t-popular__header {
    padding: 12px 8px;
  }
  .t-popular__header-title {
    margin: 12px 0;
  }
  .t-popular-card {
    box-shadow: inset 0 0 1px #000000;
  }
  .t-popular {
    margin-bottom: 30px;
  }
  .t-services__header-button {
    padding: 12px 8px;
  }
  .t-services__header {
    padding: 12px 8px;
  }
  .t-services__header-title {
    margin: 12px 0;
  }
  .t-services-card__content {
    padding: 30px 20px;
  }
  .t-services {
    margin-bottom: 30px;
  }
  .t-features__cards {
    justify-content: center;
  }
  .t-timeline {
    padding: 0 0 30px 0;
  }
  .t-timeline-card {
    flex-flow: row nowrap;
    position: relative;
  }
  .t-timeline-card:first-child {
    flex-flow: row wrap;
  }
  .t-timeline-card:first-child .t-timeline-card__wrap {
    padding-left: 50px !important;
  }
  .t-timeline-card:first-child .t-timeline-card__number {
    position: absolute;
    left: 0;
    height: 100%;
  }
  .t-timeline-card__number {
    max-width: 30px;
    min-height: 30px;
    font-size: 20px;
    z-index: 1;
    order: -1;
  }
  .t-timeline-card__content {
    order: 1;
  }
  .t-timeline-card__icon {
    order: 2;
  }
  .t-timeline-card__wrap {
    padding: 30px 20px !important;
  }
  .t-partners {
    padding: 30px 0;
  }
  .t-partners__title {
    margin-bottom: 20px;
    font-size: 32px;
    text-align: center;
  }
  .t-partners__cards {
    justify-content: center;
  }
  .t-consult__title {
    margin: 20px 0;
  }
  .t-consult__input {
    margin-bottom: 20px;
  }
  .t-consult__info {
    margin: 15px 0;
  }
  .t-home-seo {
    padding: 30px 0;
  }
  .t-home-seo__title {
    margin-bottom: 20px;
  }
  .t-footer {
    padding: 20px 0;
  }
  .t-footer__title {
    margin: 20px 0;
  }
  .t-footer__more {
    align-items: flex-start;
    padding: 20px 0;
  }
  .t-footer__bottom {
    margin: 0;
    padding-top: 20px;
  }
  .t-stocks {
    padding: 0 0 30px 0;
  }
  .t-stocks-card__image {
    height: 200px;
  }
  .t-stocks-card__content {
    padding: 30px 20px;
  }
  .t-stocks-card__content .col-2 {
    padding: 0;
  }
  .t-pagination {
    padding-top: 20px;
  }
  .t-basket__title {
    margin-bottom: 20px;
  }
  .t-basket__subtitles {
    display: none;
  }
  .t-basket_show_mob {
    display: inline-block;
    margin: 6px 12px;
    opacity: 0.7;
    color: #343433;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.7px;
  }
  .t-basket-card__price {
    display: inline-block;
  }
  .t-basket-card__delete {
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
    max-width: 24px;
    height: auto;
    max-height: 24px;
    padding: 6px;
    z-index: 1;
  }
  .t-basket-card__image {
    max-width: 100%;
    height: auto;
  }
  .t-basket-card__counter {
    margin: 20px 0;
  }
  .t-basket__summary {
    justify-content: space-around;
  }
  .t-basket__summary {
    padding: 10px 0;
  }
  .t-order {
    padding: 30px 0;
  }
  .t-order__text {
    margin-bottom: 30px;
  }
  .t-order__wrap {
    padding: 15px 0;
  }
  .t-header__more {
    width: 100%;
  }
  .t-catalog {
    padding: 0 0 30px 0;
  }
  .t-item__big-card {
    height: 260px;
  }
  .t-item__content {
    min-height: auto;
    padding: 30px 25px;
  }
  .t-item__content:after {
    font-size: 28px;
  }
  .t-item__row {
    justify-content: flex-start;
    margin-top: 10px;
  }
  .t-item__button {
    padding: 16px 57px;
  }
  .t-item {
    padding: 0 0 30px 0;
  }
  .t-tabs__header {
    justify-content: space-around;
  }
  .t-tabs__content {
    min-height: auto;
    padding: 20px 0;
    font-size: 14px;
  }
  .t-similar {
    padding: 30px 0;
  }
  .t-similar__title {
    margin-bottom: 30px;
  }
  .t-article__wrap {
    padding-top: 20px;
  }
  .t-article {
    padding: 20px 0;
  }
  .t-article__content {
    padding: 20px 0;
  }
  .t-article__content figure {
    margin-bottom: 10px;
  }
  .t-gallery__cards {
    overflow: hidden;
  }
  .t-about__blockquote {
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: flex-start;
    margin: 20px 0;
    padding: 30px 10px;
    text-align: center;
  }
  .t-about__wrap {
    margin: 0;
    padding: 30px 0 0 0;
  }
  .t-about__blockquote-left {
    padding: 10px;
  }
  .t-about__blockquote-text {
    font-size: 15px;
  }
  .t-gallery {
    padding: 20px 0;
  }
  .t-about__image {
    height: 200px;
  }
  .t-about__title {
    margin-bottom: 20px;
    font-size: 42px;
  }
  .t-about__prep {
    margin-bottom: 20px;
    font-size: 18px;
  }
  .t-gallery__title {
    margin-bottom: 20px;
  }
  .t-gallery__card {
    max-width: 100%;
    height: 240px;
  }
  .t-gallery .swiper-slide-active {
    max-width: 100%;
    height: 240px;
    margin: 0;
  }
  .t-gallery__cards {
    height: auto;
  }
  .t-gallery .swiper-button-next,
  .t-gallery .swiper-button-prev {
    top: auto;
    bottom: 0;
    opacity: 0.8;
  }
}
